/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Modal from '../../Dashboard/Modal';
import CompleteStepper from '../../Dashboard/Jobs/completeStepper';
import { FileUploader } from 'react-drag-drop-files';
import Input from '../fields/Input';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';
import Message from '../fields/Message';
import { DASHBOARD_ACTION_TYPE } from '../../../Store/dashboard/Type';

export default function CompleteJobStepPopup({ taxProUserId, data, setOuterMessage }) {

	const dispatch = useDispatch()
	const [skip, setSkip]= useState(false);
	const [file, setFile] = useState();
	const [message, setMessage] = useState({ show: false });
	const [documentName, setDocumentName] = useState();
	const [errors, setErrors] = useState({
		file: false,
		general: '',
		documentName: ''
	});

	const [{ createFileUpload, updateJobStatusByTaxPro }] = useSelector((state) => [state.dashboard]);

	useEffect(() => {
		if (createFileUpload.status === true) {
			dispatch(allActions.global.Loader(false));
			dispatch({ type: DASHBOARD_ACTION_TYPE.updateJobStatusByTaxProIdle });
			dispatch(allActions.dashboard.updateJobStatusByTaxPro(data.uuid));
		}
		if(!createFileUpload.status){
			setMessage({
				show: true,
				type: 'Error',
				text: updateJobStatusByTaxPro.message,
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [createFileUpload.status])
	

	useEffect(() => {
		if (updateJobStatusByTaxPro.status === true) {
			// loading false
			dispatch(allActions.global.Loader(false));
			setOuterMessage({
				show: true,
				type: 'Success',
				text: "job completed successfully",
			});
			handleClose();
			resetForm()
		}
		if (!updateJobStatusByTaxPro.status) {
			setMessage({
				show: true,
				type: 'Error',
				text: updateJobStatusByTaxPro.message,
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [updateJobStatusByTaxPro.status])	
	
	const handleSubmit = () => {
		if (skip) {
			setMessage({ show: false })
			setOuterMessage({show:false})
			dispatch(allActions.global.Loader(true));
			dispatch({ type: DASHBOARD_ACTION_TYPE.updateJobStatusByTaxProIdle });
			dispatch(allActions.dashboard.updateJobStatusByTaxPro(data.uuid));
		} else {
	
			// Create a new FormData instance
			const formData = new FormData();
			// Append properties to FormData
			formData.append('documents', file);
			formData.append('user_id', taxProUserId);
			formData.append('job_id', data.id);
			formData.append('type', 3);
			if (documentName) formData.append('document_name', documentName);
			if (data.user?.uuid) formData.append('target_user_uuid', data.user?.uuid);

			setMessage({ show: false })
			setOuterMessage({show:false})
			dispatch(allActions.global.Loader(true));
			dispatch({ type: DASHBOARD_ACTION_TYPE.createFileUploadIdle });
			dispatch(allActions.dashboard.createFileUpload(formData))
		}
	}

	const resetForm=()=>{
		setFile()
		setDocumentName('')
	}


const checkValidation=()=>{
	if(!file) return setMessage({type: 'Error',text: "Select Document First",show:true})
	if(!documentName) return setMessage({type: 'Error',text: "Type File Name.",show:true})


	setMessage({show:false})
	setActiveTab(3)
}


	const tab1 = () => {
		return (
			<>
				<hr className='m-0 p-0' />
				<div className='p-4 d-flex flex-column align-items-center justify-content-center'>
					<h5 className='fw-bold mt-2 w-100 text-align-left'>Complete Job</h5>
					<p className='w-100 text-align-left mt-2'>Congratulations on completing the contract! Complete the following steps to close out the job.</p>
					<img src={require('../../../assets/img/shakeplanet.png')} className='w-50 h-100 my-2' alt='shakrplanet' />
				</div>
				<hr className='m-0 p-0' />
				<div className='col-12 d-flex align-items-center justify-content-between px-3 py-3'>
					<button className='text-primary btn btn-transparent fw-bold' onClick={() => handleClose()}>Cancel</button>
					<button className='btn btn-primary rounded' onClick={() => {
						setActiveTab(2)
						setSkip(false)
						}}>
						Next: Upload Documents
					</button>
				</div>
			</>
		)
	}


	const tab2 = () => {
		return (
			<>
				<hr className='m-0 p-0' />
				<div className='p-4 d-flex flex-column align-items-center justify-content-center'>
					<h5 className='fw-bold mt-2 w-100 text-align-left'>Upload final Documents</h5>
					<p className='w-100 text-align-left mt-2'>Upload any final documents and deliverables for you job.</p>
					<div className='cutomBorderBox card w-100 px-5 py-5 d-flex flex-column align-items-center justify-content-center align-items-center bg-white' role='button'>
						{/* <LuUpload className='mt-4 mb-2' size={40} color='#4b57e7' />
						<p className='text-black m-0 p-0'>Drag file here or <span className='text-primary'>browse.</span></p>
						<p className='text-muted m-0 p-0 small-text my-2 mb-5'>PDF, PNG, JPG, Doc, Excel, CSV</p> */}
						<div className='col-lg-6 mb-4'>
							<FileUploader
								classes='form-control'
								multiple={false}
								values={file}
								handleChange={(f) => setFile(f)}
								name='file'
								types={['PDF']}
								onTypeError={() => {
									setErrors({ ...errors, file: true });
								}}
							/>
						</div>
						<div className='col-md-6 mb-3'>
							<Input
								type='text'
								value={documentName}
								name='documentName'
								placeholder='Document Name'
								label='Enter the name of the Document below'
								fn={setDocumentName}
								hasValidationError={errors.documentName}
								showLabel={false}
							/>
						</div>
					</div>
				</div>
				<hr className='m-0 p-0' />
				<div className='col-12 d-flex align-items-center justify-content-between px-3 py-3'>
					<button className='text-primary btn btn-transparent fw-bold' onClick={() => handleClose()}>Cancel</button>
					<div className='mx-2'>
						{data?.drafts?.length>0 &&
						<button className='btn btn-outline-black border border-1 border-black rounded mx-2 px-3' onClick={() => {
							setActiveTab(tab3())
							setSkip(true)
							}}>
							Skip
						</button>
						}
						<button className='btn btn-primary rounded px-3' onClick={() =>checkValidation()}>
							Next
						</button>
					</div>
				</div>
			</>
		)
	}


	const tab3 = () => {
		return (
			<>
				
				<hr className='m-0 p-0' />
				<div className='p-4 d-flex flex-column align-items-center justify-content-center'>
					<h5 className='fw-bold mt-2 w-100 text-align-left'>Almost there!</h5>
					<p className='w-100 text-align-left mt-2'>We'll send your request and final document(s) to your client for approval. Once they approve, they will finalize and send any last payment to you.</p>
					<div className='d-flex flex-row col-10 flex-wrap'>
						<img src={require('../../../assets/img/shakeplanet.png')} className='w-50 h-50 my-2' alt='shakrplanet' />
						<CompleteStepper />
					</div>
				</div>
				<hr className='m-0 p-0' />
				<div className='col-12 d-flex align-items-center justify-content-between px-3 py-3'>
					<button className='text-primary btn btn-transparent fw-bold' onClick={() => handleClose()}>Cancel</button>
					<button className='btn btn-primary rounded' onClick={() => { handleSubmit() }} >
						Submit
					</button>
				</div>
			</>
		)
	}


	const [activeTab, setActiveTab] = useState(1)


	const handleClose = () => {
		setActiveTab(1);
		document.querySelector('.CompleteJobStepPopup').click()
	}

	console.log("ACTIVE TAB>>>", activeTab);
	return (
		<Modal
			title={''}
			bodyClass=' p-0 m-0'
			btnText={""}
			trigger='CompleteJobStepPopup'
			showBtn={false}
			size={'lg'}
			btnClass={'CompleteJobStepPopup'}
			close={true}>
				<div className='col-lg-12 p-3'>{message?.show ? <Message message={message} /> : <></>}</div>
			{activeTab === 1 ? tab1() : activeTab === 2 ? tab2() : tab3()}
		</Modal>
	);
}
