/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { setQueryParams, getQueryParam, removeQueryParam } from '../../../utilites';
import Header from '../../dashboard/components/Header';
//import Modal from "../../../components/Dashboard/Modal";
import Footer from '../../dashboard/components/Footer';
import Alert from '../../dashboard/components/Alert';
import GenerateInvoice from '../../../components/forms/Modal/InvoiceModal';
import allActions from '../../../Store/action';
import Pagination from '../../../components/Pagination';
import Input from '../../../components/forms/fields/Input';
import Message from '../../../components/forms/fields/Message';
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import { formatCurrency } from '../../../utilites/functions';
import { IoIosLock } from "react-icons/io";
import InvoicePaid from '../../../components/forms/Modal/InvoicePaid';
import { JOBS_ACTION_TYPE } from '../../../Store/jobs/Type';
import Swal from 'sweetalert2'


const InvoiceList = () => {
	const dispatch = useDispatch();
	const InvoicePeriodOptions = [
		{ value: '3', label: '3' },
		{ value: '6', label: '6' },
		{ value: '12', label: '12' },
	];
	const InvoiceOptions = [
		{ value: 'FullPayment', label: 'Full Payment' },
		{ value: 'PartialPayment', label: 'Partial Payment' },
		// { value: 'RecurringPayment', label: 'Recurring Payment' },
	];

	const [
		{ user },
		{ LoginData },
		{ dashboard },
		{
			invoices,
			createCustomer,
			createCustomerMessage,
			createInvoice,
			createInvoiceMessage,
			createSubscription,
			createSubscriptionMessage,
			downloadInvoice,
			downloadInvoiceMessage,
		},
	] = useSelector((state) => [state, state.user, state, state.invoice]);
	// user.isAdmin = true;
	const [firstLoad, setFirstLoad] = useState(false);
	const [editInvoiceObj, setEditInvoiceObj] = useState({});
	const [editInvoice, setEditInvoice] = useState(false);
	const [deleteInvoice, setDeleteInvoice] = useState(false);
	const [SelectedInvoice, setSelectedInvoice] = useState('');
	const [selectedService, setselectedService] = useState('');
	const [InvoiceName, setInvoiceName] = useState('');
	const [InvoiceAmount, setInvoiceAmount] = useState('');
	const [InvoiceDueDate, setInvoiceDueDate] = useState('');
	const [InvoicePeriod, setInvoicePeriod] = useState('');
	const [message, setMessage] = useState({ show: false });
	const [listMessage, setListMessage] = useState({ show: false });
	// eslint-disable-next-line
	const [outerMessage, setOuterMessage] = useState({ show: false });
	const [SelectedLead, setSelectedLead] = useState('');
	const [leadjoblist, setLeadjoblist] = useState([]);
	const [SelectedJob, setSelectedJob] = useState('');
	const [duplicateInvoiceMessage, setDuplicateInvoiceMessage] = useState(false);
	const [warningCheckBox, setWarningCheckBox] = useState(false);
	const selectedJobId = SelectedLead?.jobs?.find(
		(data) => data?.service_id === selectedService?.value
	)?.id;
	const leadsOptions = dashboard.leads.data?.map((data) => {
		return {
			value: data.user.id,
			label: data.user.firstname + ' ' + data.user.lastname,
			phone: data.phone,
			email: data.user.email,
			customers: data.user.customers,
			jobs: data.jobs,
			services: data.services,
			lead_uuid:data?.uuid
		};
	});

	useEffect(() => {
		let jobsList = SelectedLead?.jobs
			?.filter((data) => data?.status_id === 2 || data?.status_id === 3)
			.map((data) => {
				return {
					value: data?.service_id !== null ? data?.service?.name : data?.others,
					label: `${data?.service_id !== null ? data?.service?.name : data?.others}  (${data.id})`,
					job_id: data.id,
					invoices: data?.invoices,
				};
			});
		jobsList?.push({ value: 'create Job', label: 'Create Job' });
		setLeadjoblist(jobsList);
	}, [SelectedLead]);
	const navigate = useNavigate();
	// Filtering
	const [keyword, setKeyword] = useState(getQueryParam('search') || '');
	const [dateCreated, setDateCreated] = useState(
		getQueryParam('created_after') ? new Date(getQueryParam('created_after')) : null
	);
	const [status, setStatus] = useState(getQueryParam('status') || '');
	// Pagination
	const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
	const [page, setPage] = useState(getQueryParam('page') || 1);

	// GET JOB OPTIONS
	const jobsOptions = dashboard?.services?.map((data) => {
		return {
			value: data?.id,
			label: data?.name,
		};
	});
	jobsOptions.push({
		value: 'tgp-custom-option',
		label: 'Others',
	});

	// Set Invoice Name on Basis of selected service
	useEffect(() => {
		if (selectedService?.value !== 'tgp-custom-option') {
			setInvoiceName(
				dashboard?.services.find((data) => {
					return data.id === selectedService?.value;
				})?.name
			);
		} else {
			setInvoiceName('');
		}
	}, [selectedService]);

	useEffect(() => {
		reset_message();
	}, []);

	useEffect(() => {
		if (invoices.status === 'idle') {
			dispatch(allActions.global.Loader(true));
			// dispatch(allActions.invoice.getInvoices(page, perPage));
		}
		if (!user.isRegular) {
			dispatch(allActions.dashboard.getLeads('', ''));
		}
	}, [invoices.status, user.isRegular]);

	// ******************************
	// HOOKS FOR CREATING CUSTOMER
	// ******************************
	useEffect(() => {
		if (!createCustomer) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createCustomerMessage,
			});
		}
		if (createCustomer === true) {
			let invoiceObj = {
				// customerId: createCustomerMessage.data.customer_stripe_id,
				user_id: SelectedLead?.value,
				dueDate: InvoiceDueDate,
				name: InvoiceName,
				amount: InvoiceAmount,
				job_id:SelectedJob?.job_id
			};
			if (selectedJobId) {
				invoiceObj.job_id = selectedJobId;
			}
			dispatch(allActions.invoice.createInvoice(invoiceObj));
		}
	}, [
		createCustomer,
		createCustomerMessage,
		InvoiceDueDate,
		InvoiceName,
		InvoiceAmount,
		selectedJobId,
		dispatch,
	]);

	// ******************************
	// HOOKS FOR DOWNLOAD INVOICE
	// ******************************

	useEffect(() => {
		if (!downloadInvoice) {
			setListMessage({
				show: true,
				type: 'Error',
				text: downloadInvoiceMessage,
			});
			dispatch(allActions.global.Loader(false));
		}

		if (downloadInvoice === true) {
			dispatch(allActions.global.Loader(false));
			window.open(downloadInvoiceMessage?.invoice_pdf);
			dispatch(allActions.invoice.resetDownloadInvoice());
		}
	}, [downloadInvoice, downloadInvoiceMessage]);

	// Used for Add , Edit and delete
	useEffect(() => {
		// Show message outside of modal For Deletion Error
		if (!createInvoice && deleteInvoice) {
			dispatch(allActions.global.Loader(false));
			setOuterMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
			return;
		}
		// Show Error Message in modal For Edit and Add
		if (!createInvoice) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
		}

		let invoiceMessage = editInvoice
			? 'Invoice update Successfully!'
			: deleteInvoice
			? 'Invoice delete Successfully!'
			: 'Generate Invoice Successfully!';

		if (createInvoice === true) {
			if (SelectedInvoice?.value === 'RecurringPayment') {
				let subscriptionObj = {
					// customerId: createCustomerMessage.data.customer_stripe_id,
					user_id: SelectedLead?.value,
					start_date: moment.utc(InvoiceDueDate).add(1, 'month').format('MM/DD/YYYY'),
					description: InvoiceName,
					amount: InvoiceAmount,
					iterations: InvoicePeriod.value - 1,
				};
				if (selectedJobId) {
					subscriptionObj.job_id = selectedJobId;
				}
				dispatch(allActions.invoice.createSubscription(subscriptionObj));
			} else {
				if (deleteInvoice) {
					reset_states();
				} else {
					document.querySelector('.invoiceModaltoggle').click();
				}
				CloseModalOverlay();
				reset_Form();
				setOuterMessage({
					show: true,
					type: 'Success',
					text: invoiceMessage,
				});
				dispatch(allActions.global.Loader(false));
			}
		}
	}, [
		createInvoice,
		createInvoiceMessage,
		InvoiceAmount,
		InvoiceDueDate,
		InvoiceName,
		InvoicePeriod,
		SelectedInvoice,
		dispatch,
	]);

	useEffect(() => {
		if (!createSubscription) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createSubscriptionMessage,
			});
		}
		if (createSubscription === true) {
			console.log('CLOSE MODAL>>>');
			document.querySelector('.invoiceModaltoggle').click();
			CloseModalOverlay();
			reset_Form();
			setOuterMessage({
				show: true,
				type: 'Success',
				text: 'Generate Invoice Successfully!',
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [createSubscription, createSubscriptionMessage, dispatch]);
	// ******************************
	// HOOKS FOR GETTING INVOICES
	// ******************************
	useEffect(() => {
		if (!invoices?.status) {
			setOuterMessage({
				show: true,
				type: 'Error',
				text: invoices?.message,
			});
			dispatch(allActions.global.Loader(false));
		}
		console.log(invoices.message);
	}, [invoices]);
	console.log('invoices', invoices);
	// *********************************************
	// Handle Loading For Fetching Data on Load
	// *********************************************

	useEffect(() => {
		if (invoices.status === 'idle' || !dashboard.leads.loaded) {
			dispatch(allActions.global.Loader(true));
		}
		if (
			invoices.status === true &&
			(dashboard.leads.loaded || user.LoginData.isCustomer) &&
			!firstLoad
		) {
			setOuterMessage({ show: false });
			dispatch(allActions.global.Loader(false));
			setFirstLoad(true);
		}
		console.log('invoices?.status', dashboard.leads.loaded);
	}, [invoices.status, dashboard.leads.loaded, user.LoginData.isCustomer, dispatch]);

	const reset_message = () => {
		// it indicate we are not  deleting invoice but clearing All messages
		setDeleteInvoice(false);
		setMessage({ show: false });
		setOuterMessage({ show: false });
		reset_states();
	};

	const reset_states = () => {
		dispatch(allActions.invoice.resetCreateCustomer());
		dispatch(allActions.invoice.resetCreateInvoice());
		dispatch(allActions.invoice.resetcreateSubscription());
	};
	const reset_Form = () => {
		setMessage({ show: false });
		setOuterMessage({ show: false });
		setSelectedInvoice('');
		setSelectedLead('');
		setselectedService('');
		setInvoiceName('');
		setInvoiceAmount('');
		setInvoiceDueDate('');
		setInvoicePeriod('');
		setSelectedJob('')
	};

	const set_EditForm = (data) => {
		const formattedDate = moment(data?.due_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
		document.querySelector('.invoiceModaltoggle').click();
		// setSelectedInvoice("");
		setSelectedLead({
			value: data.customers.user.id,
			label: data.customers.user.firstname + ' ' + data.customers.user.lastname,
		});
		setInvoiceName(data.name);
		setInvoiceAmount(data.amount);
		// setInvoiceDueDate(moment.utc(data.created_at).format("MM/DD/YYYY"));
		setInvoiceDueDate(formattedDate);
		// setInvoicePeriod("");
		setEditInvoice(true);
		setDeleteInvoice(false);
	};

	const handleDelete = (data) => {
		dispatch(allActions.global.Loader(true));
		let invoiceObj = {
			invoiceId: data.stripe_invoice_id,
		};
		dispatch(allActions.invoice.removeInvoice(invoiceObj));
	};

	const handleSubmit = (e) => {
		if(SelectedJob?.value==='create Job'){
			document.querySelector('.invoiceModaltoggle').click();
          let paramsData={
			invoiceType:SelectedInvoice,
			invoiceAmount:InvoiceAmount,
			invoiceDueDate:InvoiceDueDate,
			
		  }
			const createContractUrl = `/dashboard/jobs/create/${SelectedLead?.lead_uuid}`;
			navigate(createContractUrl,{state:{paramsData}});
		}else{	

			var duplicateInvoice=false
			// eslint-disable-next-line array-callback-return
			SelectedJob?.invoices.map((v)=>{
              if(v.amount===InvoiceAmount){
				duplicateInvoice=true
			  }
		})
		if(duplicateInvoice===true && !warningCheckBox){
			setDuplicateInvoiceMessage(true)
			e.preventDefault();
			return 
		}
		e.preventDefault();
		reset_message();
		dispatch(allActions.global.Loader(true));
		let customerObj = {
			user_id: SelectedLead?.value,
			email: SelectedLead?.email,
			phone: SelectedLead?.phone,
			name: SelectedLead?.label,
		};
		if (SelectedLead.customers.length === 0) {
			dispatch(allActions.invoice.createCustomer(customerObj));
		} else {
			let invoiceObj = {
				// customerId: LoginData.customers[0].customer_stripe_id,
				user_id: SelectedLead?.value,
				dueDate: InvoiceDueDate,
				name: InvoiceName,
				amount: InvoiceAmount,
				job_id:SelectedJob?.job_id
			};
			if (selectedJobId) {
				invoiceObj.job_id = selectedJobId;
			}
			dispatch(allActions.invoice.createInvoice(invoiceObj));
		}

		setDuplicateInvoiceMessage(false)
		setWarningCheckBox(false)
	}
	};

	const handleUpdate = (e) => {
		e.preventDefault();
		reset_message();
		dispatch(allActions.global.Loader(true));

		let invoiceObj = {
			dueDate: InvoiceDueDate,
			name: InvoiceName,
			amount: InvoiceAmount,
			invoiceId: editInvoiceObj.stripe_invoice_id,
		};
		dispatch(allActions.invoice.updateInvoice(invoiceObj));
	};
	const CloseModalOverlay = () => {
		try {
			// Remove "modal-open" class from the body tag
			document.body.classList.remove('modal-open');

			// Reset the inline styles on the body tag
			document.body.style.overflow = '';
			document.body.style.paddingRight = '';

			// Remove the modal backdrop element
			var backdrop = document.querySelector('.modal-backdrop');
			if (backdrop) {
				backdrop.parentNode.removeChild(backdrop);
			}
		} catch (error) {}
	};

	useEffect(() => {
		dispatch(allActions.invoice.getInvoices(keyword, status, dateCreated, page, perPage));
	}, [dispatch, keyword, status, dateCreated, page, perPage]);

	const moveTo = (page) => {
		setPage(page);
		// Set query param
		setQueryParams('page', page);
	};

	const changePerPage = (perPage) => {
		setPerPage(perPage);
		// Set query param
		setQueryParams('per_page', perPage);
	};

	const filterByKeyword = (keyword) => {
		setKeyword(keyword);
		// Set query param
		if (!keyword) {
			removeQueryParam('search');
		} else {
			setQueryParams('search', keyword);
		}
	};

	const filterByStatus = (st) => {
		setStatus(st);
		// Set query param
		if (!st) {
			removeQueryParam('status');
		} else {
			setQueryParams('status', st);
		}
	};

	const filterByDateCreated = (date) => {
		setDateCreated(date);
		// Set query param
		if (!date) {
			removeQueryParam('created_after');
		} else {
			setQueryParams('created_after', date);
		}
	};

	return (
		<div className="clearfix dashboard-Page">
			<Header name="Invoices" />
			<div className="container page">
				<div className="clearfix mb-4">
					<div className="row justify-content-center">
						<div className="col-md-12 p-0">
							{listMessage?.show ? <Message message={listMessage} /> : <></>}
							{user.isTaxPro && LoginData?.userpaymentmethod.length === 0 && (
								<Alert
									notice="You have not add a payment method yet."
									message="Payouts and transfers are disabled until you can add a debit card or bank."
									type="danger"
								/>
							)}
							<div className="clearfix mt-4">
								<div className="clearfix mt-4 mb-5">
									<div className="row justify-content-between">
										<div className="col-md-10">
											<div className="row g-3">
												<div className="col-md-3">
													<Input
														type="text"
														value={keyword}
														name="keyword"
														inputClass="search-input border h-auto min-height-initial"
														showLabel={false}
														required={false}
														placeholder="Search by title"
														fn={filterByKeyword}
														extraSetter={() => {
															if (page !== 1) moveTo(1);
														}}
													/>
												</div>
												<div className="col-md-2">
													<div className="form-floating">
														<DatePicker
															className="form-select"
															wrapperClassName="d-block"
															selected={dateCreated}
															maxDate={new Date()}
															onChange={(date) => {
																filterByDateCreated(date);
																if (page !== 1) moveTo(1);
															}}
															dateFormat="MMMM d, yyyy"
															placeholderText="Date Created"
															isClearable
														/>
													</div>
												</div>
												<div className="col-md-2">
													<select
														className="form-select"
														id="filter"
														name="status"
														onChange={(e) => {
															filterByStatus(e.target.value);
															if (page !== 1) moveTo(1);
														}}
														value={status}
													>
														<option value="">Filter by status</option>
														<option value="pending">Pending</option>
														<option value="paid">Paid</option>
													</select>
												</div>
											</div>
										</div>
										{LoginData?.userpro?.status_id === 6 ? (
											<div className="col-md-2">
												<button
													className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
													disabled={true}
												>
													Add invoice <IoIosLock style={{ fontSize: '20px' }} />
												</button>{' '}
											</div>
										) : (
											!LoginData.isCustomer && (
												<div className="col-md-2">
													<Link
														to="#"
														className="btn btn-primary float-center float-md-end mt-3 mt-md-0 invoiceModaltoggle"
														data-bs-toggle="modal"
														data-bs-target="#addNewInvoiceModal"
														onClick={() => {
															setEditInvoice(false);
															setDeleteInvoice(false);
															reset_Form();
															reset_message();
														}}
													>
														Add invoice
													</Link>
												</div>
											)
										)}
									</div>
								</div>
							</div>
							<div className="clearfix">
								<div className="card border-0">
									<div className="clearfix position-relative">
										<div className="table-responsive">
											<table className="table">
												<thead className="text-dark text-opacity-50">
													<tr>
														<th scope="col">ID</th>
														<th scope="col">Title</th>
														<th scope="col">Client</th>
														<th scope="col">Services</th>
														<th scope="col">Amount</th>
														<th scope="col">Created On</th>
														<th scope="col">Status</th>
														<th className="text-center" scope="col">
															Actions
														</th>
													</tr>
												</thead>
												<tbody>
													{invoices.status &&
														invoices?.data &&
														invoices?.data.slice()?.map((data, index) => {
															let paidAmount = 0;
															data?.payments?.map(
																(i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid))
															);
															return (
																<tr key={index}>
																	<th scope="row">{data.id}</th>
																	<td>
																		<Link
																			to={`/dashboard/invoices/${data.stripe_invoice_id}`}
																			onClick={() => {
																				dispatch(allActions.invoice.resetPayInvoice());
																				dispatch(allActions.payment.resetAddPayment());
																				dispatch(allActions.payment.resetModifyPayment());
																			}}
																		>
																			{data.name}
																		</Link>
																	</td>
																	<td>
																		{data.customers?.user?.firstname +
																			' ' +
																			data.customers?.user?.lastname}
																	</td>
																	<td>
																		<Link
																			to={
																				data?.job?.uuid && `/dashboard/jobs/${data?.job?.uuid}` 
																			}
																			className="text-decoration-none"
																			onClick={() => {
																				if (!data?.job?.uuid) {
																					Swal.fire({
																						icon: 'error',
																						title: 'Oops...',
																						text: 'That invoice is not linked with any of the Job',
																					});
																				} else {
																					dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
																					setMessage({ show: false });
																				}
																			}}
																		>
																			{data?.job?.service?.name || data.name}
																		</Link>
																	</td>
																	<td>{formatCurrency(data?.amount)}</td>
																	<td>{new Date(data.created_at).toLocaleDateString()}</td>
																	<td>
																		{' '}
																		{parseInt(data?.amount) - paidAmount <= 0 ? 'Paid' : ' Pending'}
																	</td>
																	<td className="text-end">
																		<div className="dropdown-center">
																			<button
																				type="button"
																				className={`btn btn-sm btn-outline-primary rounded-circle ${
																					LoginData.isCustomer &&
																					!(parseInt(data?.amount) - paidAmount <= 0) &&
																					'grey-200 disabled'
																				}`}
																				data-bs-toggle="dropdown"
																				aria-expanded="false"
																			>
																				<BsThreeDotsVertical className="" />
																			</button>
																			{parseInt(data?.amount) - paidAmount <= 0 && (
																				<ul className="dropdown-menu border-light">
																					<li>
																						<Link
																							onClick={() => {
																								setListMessage({ show: false });
																								dispatch(allActions.global.Loader(true));
																								dispatch(allActions.invoice.resetDownloadInvoice());
																								dispatch(
																									allActions.invoice.downloadInvoice(
																										data?.stripe_invoice_id
																									)
																								);
																							}}
																							className="dropdown-item"
																							to="#"
																						>
																							Download
																						</Link>
																						{user?.isAdmin && (
																							<>
																								<li>
																									<hr className="dropdown-divider border-light" />
																								</li>
																								<Link
																									onClick={() => {
																										reset_message();
																										setListMessage({ show: false });
																										setSelectedInvoice(data);
																									}}
																									to={'#'}
																									className="dropdown-item"
																									data-bs-toggle="modal"
																									data-bs-target="#invoicePaid"
																								>
																									Transfer Fund
																								</Link>
																							</>
																						)}
																					</li>
																				</ul>
																			)}
																			{!(parseInt(data?.amount) - paidAmount <= 0) && (
																				<ul className="dropdown-menu border-light">
																					<li>
																						<Link
																							onClick={() => {
																								reset_message();
																								set_EditForm(data);
																								setEditInvoiceObj(data);
																							}}
																							className="dropdown-item"
																							to="#"
																						>
																							Edit
																						</Link>
																					</li>
																					<li>
																						<hr className="dropdown-divider border-light" />
																					</li>
																					<li>
																						<Link
																							to="#addNewInvoiceModal"
																							className="dropdown-item text-danger"
																							onClick={() => {
																								reset_message();
																								setEditInvoice(false);
																								setDeleteInvoice(true);
																								handleDelete(data);
																							}}
																						>
																							Delete
																						</Link>
																					</li>
																					{user?.isAdmin && (
																						<>
																							<li>
																								<hr className="dropdown-divider border-light" />
																							</li>
																							<li>
																								<Link
																									onClick={() => {
																										reset_message();
																										setListMessage({ show: false });
																										setSelectedInvoice(data);
																									}}
																									to={'#'}
																									className="dropdown-item"
																									data-bs-toggle="modal"
																									data-bs-target="#invoicePaid"
																								>
																									Transfer Fund
																								</Link>
																							</li>
																						</>
																					)}
																				</ul>
																			)}
																		</div>
																	</td>
																</tr>
															);
														})}
													{invoices?.status === true && invoices?.data?.length === 0 ? (
														<tr className="text-center text-muted">
															<td colSpan={8}>You have not created any invoice yet.</td>
														</tr>
													) : (
														<></>
													)}
												</tbody>
											</table>
										</div>
									</div>
									<Pagination
										pageCount={Math.ceil(invoices.count / perPage)}
										currentPage={parseInt(page)}
										setCurrentPage={moveTo}
										perPage={perPage}
										setPerPage={(pc) => {
											changePerPage(pc);
											// Move to first page
											if (page !== 1) moveTo(1);
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<GenerateInvoice
				message={message}
				InvoiceName={InvoiceName}
				setInvoiceName={setInvoiceName}
				InvoiceDueDate={InvoiceDueDate}
				setInvoiceDueDate={setInvoiceDueDate}
				InvoicePeriod={InvoicePeriod}
				InvoiceAmount={InvoiceAmount}
				setInvoiceAmount={setInvoiceAmount}
				setInvoicePeriod={setInvoicePeriod}
				InvoiceOptions={InvoiceOptions}
				SelectedInvoice={SelectedInvoice}
				setSelectedInvoice={setSelectedInvoice}
				InvoicePeriodOptions={InvoicePeriodOptions}
				handleSubmit={handleSubmit}
				handleUpdate={handleUpdate}
				handleCancel={reset_message}
				leadsOptions={leadsOptions}
				SelectedLead={SelectedLead}
				setSelectedLead={setSelectedLead}
				editInvoice={editInvoice}
				jobsOptions={jobsOptions}
				selectedService={selectedService}
				setselectedService={setselectedService}
				SelectedJob={SelectedJob}
				setSelectedJob={setSelectedJob}
				duplicateInvoiceMessage={duplicateInvoiceMessage}
				warningCheckBox={warningCheckBox}
				setDuplicateInvoiceMessage={setDuplicateInvoiceMessage}
				setWarningCheckBox={setWarningCheckBox}
				leadjoblist={leadjoblist}
			/>
			<InvoicePaid
				selectedInvoice={SelectedInvoice}
				setSelectedInvoice={setSelectedInvoice}
				message={message}
				setMessage={setMessage}
				setListMessage={setListMessage}
			/>
		</div>
	);
};

export default InvoiceList;
