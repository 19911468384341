import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import ReactSelect from "react-select";
import { Scrollbar } from "react-scrollbars-custom";
import Calendar from "react-calendar";

import {
  BASEURL,
  useAutoReplyHook,
  timezoneFormatter,
  convertTime,
  convertTimeIntoSselectedTZ,
} from "../../utilites";
import Input from "./fields/Input";
import TextArea from "./fields/TextArea";
import Button from "./fields/Button";
import Select from "./fields/Select";
import Checkbox from "./fields/Checkbox";
// CSS
import "react-datepicker/dist/react-datepicker.css";
import allActions from "../../Store/action";
import { useDispatch, useSelector } from "react-redux";
import Message from "./fields/Message";
import { CALL_ACTION_TYPE } from "../../Store/call/Type";
import moment from "moment";
import { useLocation } from 'react-router-dom';


const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const americanTimeZones = moment.tz.zonesForCountry("US");

export default function AddAppointmentForm({
  appointmentTypes,
  users,
  setAlert,
  reload,
  allAppointments
}) {  
  const [{ LoginData }, { personalConnections }] = useSelector((state) => [
    state.user,
    state.firebase,
  ]);
  const [type, setType] = useState(appointmentTypes[0].id.toString());
  const [reason, setReason] = useState("");
  const [details, setDetails] = useState("");
  const [timezone, setTimezone] = useState(
    moment.tz.guess().startsWith("America/")
      ? moment.tz.guess()
      : "America/Adak"
  );
  const location = useLocation();

  const durationOptions = [
    { id: 30, name: "30 Minutes" },
    { id: 60, name: "60 Minutes" },
  ];

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [duration, setDuration] = useState([durationOptions[0].id.toString()]);

  const [videoCheck, setVideoCheck] = useState(true);
  const [message, setMessage] = useState({ show: false });
  const [availableTime, setAvailableTime] = useState([]);
  const [{ videoCallRoom }] = useSelector((state) => [state.call]);
  const [availableTimeTZ, setAvailableTimeTZ] = useState([]);
  const [timezoneName, setTimezoneName] = useState(
    moment.tz.guess().startsWith("America/")
      ? moment.tz.guess()
      : "America/Adak"
  );
  var userAvailTZ = null;
  switch (LoginData?.roles[0]?.name) {
    case "customer":
      userAvailTZ = LoginData?.lead?.[0]?.userpro?.user;
      break;
    case "taxpro":
      userAvailTZ = LoginData;
      break;

    default:
      break;
  }
  console.log("@000", userAvailTZ);
  const topTimezones = [
    "America/New_York",
    "America/Chicago",
    "America/Denver",
    "America/Los_Angeles",
  ];
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({
    type: false,
    reason: false,
    details: false,
    timezone: false,
    selectedUsers: false,
    startDate: false,
    duration: false,
    general: "",
  });

  const button = useRef();
  const dispatch = useDispatch();
  const handleAutoReply = useAutoReplyHook(dispatch);

  const queryParams = new URLSearchParams(location.search);
  const qaMode = queryParams.get('qa_mode');

  useEffect(() => {
    if (videoCallRoom.status === "idle") resetMessage();

    // if Error While Creating Video Call Room
    if (!videoCallRoom.status) {
      if(button.current) button.current.disabled = false;
      // Reset Message and Loading
      resetMessage();
      setMessage({
        show: true,
        type: "Error",
        text: videoCallRoom.message,
      });
      changeLoading(false);
    }
    // if Success After Creating Video Call
    if (videoCallRoom.status === true) {
      changeLoading(false);
      if(button.current) button.current.disabled = false;
      // Reset Message and Loading
      resetMessage();

      // Encode Room ID String
      if (videoCallRoom?.data?.meetingID) {
        bookVideoAppoinment(videoCallRoom?.data?.meetingID);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoCallRoom, videoCallRoom.message, videoCallRoom.status]);
  // Function To video Appoinment
  const bookVideoAppoinment = (roomId) => {
    if(button.current) button.current.disabled = true;
    if (errors.general !== "") setErrors({ errors, general: "" });

    // Get endDate based on the selected duration and startDate
    const durationInMinutes = parseInt(duration[0]);
    const end_date = new Date(startDate);
    end_date.setMinutes(startDate.getMinutes() + durationInMinutes);
      // console.log("@33333",end_date);
      
    const appointmentObj = {
      type,
      reason,
      details,
      timezone: userAvailTZ?.settings?.timezone,
      selectedUsers,
      roomId,
      startDate: convertTimeIntoSselectedTZ(
        timezone,
        userAvailTZ?.settings?.timezone,
        startDate
      ),
      endDate: convertTimeIntoSselectedTZ(
        timezone,
        userAvailTZ?.settings?.timezone,
        end_date
      ),
    };
    if (
      LoginData.roles[0].name === "customer" &&
      LoginData?.lead?.[0]?.userpro?.user
    ) {
      appointmentObj.selectedUsers = [LoginData?.lead?.[0]?.userpro?.user?.id];
    }
    axios
      .post(`${BASEURL}/api/appointments/?qa_mode=on`, appointmentObj)
      .then(async (resp) => {
        console.log(resp)
        handleVideoMeetingReply(resp.data.data.creator_user_id,resp.data.data.id,resp.data.data.uuid);
        if(button.current) button.current.disabled = false;
        // Reload appointments
        reload();
        // Close Modal
        document.querySelector(".createAppointmentModalClose").click();
        setAlert("Appointment has been added successfully!");
        handlePreviousStep();
      })
      .catch((error) => {
        if(button.current) button.current.disabled = false;
        // Set general error
        if (errors.general === "")
        setErrors({
            errors,
            general: error?.response?.data?.error || error.message,
          });
      });
  };
  const changeLoading = (status) => {
    dispatch(allActions.global.Loader(status));
  };



  const handleVideoMeetingReply = (creator_id,appointment_id,uuid) => {
    handleAutoReply({
      personalConnections,
      LoginData,
      creator_id,
      receiver:LoginData.roles[0].name === 'customer' ? LoginData.lead[0].userpro.user : users?.find((data) => data?.id === selectedUsers?.[0]),
      type: "simple",
      encodedString: `<p>Hi, I just scheduled an appointment for us to meet up and discuss. You should be receiving an email about the appointment shortly or you can visit the appointments page by clicking on the link below to confirm the appointment. Thanks</p>
      <img src="https://cdn-icons-png.flaticon.com/512/2666/2666523.png" class="bg-white p-1 rounded" alt="Girl in a jacket" width="60" height="60">
      <br>
      <br>
      <a href="${window.location.origin}/appointment-details/${uuid}" style="margin-right: 10px;">View Appointment</a>
      <a  href="${window.location.origin}/dashboard/appointments/change-status/1/${uuid}" style="color: #28a745; margin-right: 10px;">Confirm</a>
      <a  href="${window.location.origin}/dashboard/appointments/change-status/2/${uuid}" style="color: #dc3545;">Reject</a>`
      ,
    });
  };
  // function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    resetMessage();
    if(button.current) button.current.disabled = true;
    if (errors.general !== "") setErrors({ errors, general: "" });
    changeLoading(true);
    // Get endDate based on the selected duration and startDate
    const durationInMinutes = parseInt(duration[0]);
    const end_date = new Date(startDate);
    end_date.setMinutes(startDate.getMinutes() + durationInMinutes);

    // appointment handling end point
    if (LoginData?.isCustomer || LoginData?.isTaxPro) {
      addAppointmentInCalendars(end_date, durationInMinutes)
    } else {
      addAppointment(end_date, durationInMinutes);
    }
  };



  const addAppointmentInCalendars = async (end_date, durationInMinutes) => {
    const callback = (meetingID) => {
     addAppointmentWithOutlook(end_date, durationInMinutes, meetingID)
     addAppointmentWithGoogleCalendar(end_date, durationInMinutes, meetingID)
    }
    await addAppointment(end_date, durationInMinutes, callback)
  }

  
  const addAppointmentWithOutlook = async (end_date, durationInMinutes, meetingID) => {
     if(meetingID !== 'no-video'){
      await axios
			.get(`${BASEURL}/api/meeting/getZoomMeeting/${meetingID}`)
			.then(async (response) => {
         let appointmentDetail = {
          startTime: startDate,
          endTime: end_date,
          timeZone: timezone,
          subject: reason,
          description:details,
          zoomUrl:response.data.data.start_url
        };
        await axios
         .post(`${BASEURL}/api/outlookcalendar/add-event`, appointmentDetail)
         .catch(async (error) => {
           if (
             error?.response?.data?.error ===
             "Appointment is not available for that timeslot"
           ) {
            if(button.current) button.current.disabled = false;
             console.error(error.message);
             changeLoading(false);
             if (errors.general === "")
               setErrors({
                 errors,
                 general: error?.response?.data?.error || error.message,
               });
           } else {
            //  await addAppointment(end_date, durationInMinutes);
           }
         });
			})
     }else{
      let appointmentDetail = {
        startTime: startDate,
        endTime: end_date,
        timeZone: timezone,
        subject: reason,
        description:details,
        zoomUrl:'no-video'
      };
      await axios
       .post(`${BASEURL}/api/outlookcalendar/add-event`, appointmentDetail)
       .catch(async (error) => {
         if (
           error?.response?.data?.error ===
           "Appointment is not available for that timeslot"
         ) {
          if(button.current) button.current.disabled = false;
           console.error(error.message);
           changeLoading(false);
           if (errors.general === "")
             setErrors({
               errors,
               general: error?.response?.data?.error || error.message,
             });
         } else {
           await addAppointment(end_date, durationInMinutes);
         }
       });
      
     }
  };
  const addAppointmentWithGoogleCalendar = async (end_date, durationInMinutes,meetingID) => {
    if(meetingID !== 'no-video'){
      await axios
			.get(`${BASEURL}/api/meeting/getZoomMeeting/${meetingID}`)
			.then(async (response) => {
         let appointmentDetail = {
          startTime: startDate,
          endTime: end_date,
          timeZone: timezone,
          subject: reason,
          description:details,
          zoomUrl:response.data.data.start_url
        };
        await axios
         .post(`${BASEURL}/api/googlecalendar/add-event`, appointmentDetail)
         .catch(async (error) => {
           if (
             error?.response?.data?.error ===
             "Appointment is not available for that timeslot"
           ) {
            if(button.current) button.current.disabled = false;
             console.error(error.message);
             changeLoading(false);
             if (errors.general === "")
               setErrors({
                 errors,
                 general: error?.response?.data?.error || error.message,
               });
           }
         });
			})
    }else{
      let appointmentDetail = {
        startTime: startDate,
        endTime: end_date,
        timeZone: timezone,
        subject: reason,
        description:details,
        zoomUrl:'no-video'
      };
      await axios
       .post(`${BASEURL}/api/googlecalendar/add-event`, appointmentDetail)
       .catch(async (error) => {
         if (
           error?.response?.data?.error ===
           "Appointment is not available for that timeslot"
         ) {
          if(button.current) button.current.disabled = false;
           console.error(error.message);
           changeLoading(false);
           if (errors.general === "")
             setErrors({
               errors,
               general: error?.response?.data?.error || error.message,
             });
         } 
       });
    }
    };

  

  const addAppointment = async (end_date, durationInMinutes,callback) => {
    // reset Meeting Link
    if (videoCheck) {
      const meetingData = {
        duration: durationInMinutes,
        email: LoginData?.email,
        startTime: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        timezone: timezone,
      };
      dispatch(
        allActions.call.dispatchToStore({
          type: CALL_ACTION_TYPE.videoCallRoomIdle,
        })
      );
      dispatch(allActions.call.createRoom(meetingData,callback));
      return;
    }
    if(callback){
      callback('no-video')
    }
    const appointmentObj = {
      type,
      reason,
      details,
      timezone: userAvailTZ?.settings?.timezone,
      selectedUsers,
      startDate: convertTimeIntoSselectedTZ(
        timezone,
        userAvailTZ?.settings?.timezone,
        startDate
      ),
      endDate: convertTimeIntoSselectedTZ(
        timezone,
        userAvailTZ?.settings?.timezone,
        end_date
      ),
    };
    if (
      LoginData.roles[0].name === "customer" &&
      LoginData?.lead?.[0]?.userpro?.user
    ) {
      appointmentObj.selectedUsers = [LoginData?.lead?.[0]?.userpro?.user?.id];
    }
    await axios
      .post(`${BASEURL}/api/appointments/?qa_mode=on`, appointmentObj)
      .then(async () => {
        handleAutoReply({
          personalConnections,
          LoginData,
          receiver: users?.find((data) => data?.id === selectedUsers?.[0]),
          type: "meeting/video",
          encodedString: `no-meeting`,
        });
        if(button.current) button.current.disabled = false;
        // Reload appointments
        reload();
        // Close Modal
        document.querySelector(".createAppointmentModalClose").click();
        setAlert("Appointment has been added successfully!");
        changeLoading(false);
      })
      .catch((error) => {
        if(button.current) button.current.disabled = false;
        console.error(error.message);
        changeLoading(false);
        // Set general error
        if (errors.general === "")
          setErrors({
            errors,
            general: error?.response?.data?.message || error.message,
          });
      });
    // Reset the form
    setType(appointmentTypes[0].id.toString());
    setReason("");
    setDetails("");
    setTimezone(
      moment.tz.guess().startsWith("America/")
        ? moment.tz.guess()
        : "America/Adak"
    );
    setSelectedUsers([]);
    setStartDate(null);
    setAvailableTime([]);
    setDuration([durationOptions[0].id.toString()]);
    setVideoCheck(true);
    setStep(1);
  };
  const handleUserSelect = (selectedOptions) => {
    setSelectedUsers([selectedOptions.value]);
  };
  const isExcludedDate = (date) => {
    const day = dayNames[date.getDay()];
    if (!userAvailTZ?.availability?.time_ranges[day]) return true;
    return false;
  };
  const resetMessage = () => {
    changeLoading(false);
    setMessage({ show: false });
  };

  useEffect(()=>{
    if(allAppointments?.loaded===true){
      changeLoading(false);

    }else{
      changeLoading(true);

    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[allAppointments])
  useEffect(() => {
    // check taxpro availability
    let taxProAvailability = false;
    for (let index = 0; index < dayNames.length; index++) {
      if (userAvailTZ?.availability?.time_ranges[dayNames[index]]) {
        taxProAvailability = true;
        break;
      }
    }
    if (taxProAvailability) {
      // settaxProAvailability(userAvailTZ?.availability?.time_ranges);
      istaxProAvailable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAvailTZ?.availability?.time_ranges]);

  const istaxProAvailable = () => {
    let i = 0;
    let date = new Date();
    while (i < 1) {
      date.setDate(date.getDate() + 1);
      const day = dayNames[date.getDay()];
      if (userAvailTZ?.availability?.time_ranges[day] !== null) {
        setStartDate(date);
        const currentDate = moment(date);
        const dayOfWeek = currentDate.format("dddd");
        const slotDuration = duration;
        const timeSlots = [];
        const originaltimeSlots = [];
        console.log(
          "@999",
          userAvailTZ?.availability?.time_ranges[dayOfWeek],
          userAvailTZ?.availability
        );
        userAvailTZ?.availability?.time_ranges[dayOfWeek]?.length > 0 &&
          userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
            originaltimeSlots.push(
              ...getTimeSlots(
                v.from,
                v.to,
                slotDuration,
                currentDate.format("YYYY-MM-DD")
              ).originaltimeSlots
            );
            timeSlots.push(
              ...getTimeSlots(
                v.from,
                v.to,
                slotDuration,
                currentDate.format("YYYY-MM-DD")
              ).timeSlots
            );
            return v;
          });

        setAvailableTime(originaltimeSlots);
        setAvailableTimeTZ(timeSlots);
        i++;
      }
    }
  };

  const haandleChange = (date) => {
    setStartDate(date);
    const currentDate = moment(date);
    const dayOfWeek = currentDate.format("dddd");
    const slotDuration = duration;
    const timeSlots = [];
    const originaltimeSlots = [];
    setAvailableTime();
    userAvailTZ?.availability?.time_ranges[dayOfWeek].length > 0 &&
      userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
        originaltimeSlots.push(
          ...getTimeSlots(
            v.from,
            v.to,
            slotDuration,
            currentDate.format("YYYY-MM-DD")
          ).originaltimeSlots
        );
        timeSlots.push(
          ...getTimeSlots(
            v.from,
            v.to,
            slotDuration,
            currentDate.format("YYYY-MM-DD")
          ).timeSlots
        ); 
        return v;
      });
        
    setAvailableTime(originaltimeSlots);
    setAvailableTimeTZ(timeSlots);
  };


  const qaHandleChange = (date) => {
    setStartDate(date);
    const currentDate = moment(date);
    const slotDuration = duration;
    const timeSlots = [];
    const originaltimeSlots = [];
    setAvailableTime();
      [1]?.map((v, i) => {
        originaltimeSlots.push(
          ...getTimeSlots(
            "00:00",
            "23:00",
            slotDuration,
            currentDate.format("YYYY-MM-DD")
          ).originaltimeSlots
        );
        timeSlots.push(
          ...getTimeSlots(
            "00:00",
            "23:00",
            slotDuration,
            currentDate.format("YYYY-MM-DD")
          ).timeSlots
        ); 
        return v;
      });
        
    setAvailableTime(originaltimeSlots);
    setAvailableTimeTZ(timeSlots);
  };

  const haandleDurationChange = (due) => {
    const currentDate = moment(startDate);
    const dayOfWeek = currentDate.format("dddd");
    const slotDuration = due;
    const timeSlots = [];
    const originaltimeSlots = [];
    setAvailableTime();
    userAvailTZ?.availability?.time_ranges[dayOfWeek].length > 0 &&
      userAvailTZ?.availability?.time_ranges[dayOfWeek]?.map((v, i) => {
        originaltimeSlots.push(
          ...getTimeSlots(
            v.from,
            v.to,
            slotDuration,
            currentDate.format("YYYY-MM-DD")
          ).originaltimeSlots
        );
        timeSlots.push(
          ...getTimeSlots(
            v.from,
            v.to,
            slotDuration,
            currentDate.format("YYYY-MM-DD")
          ).timeSlots
        );
        return v;
      });

    setAvailableTime(originaltimeSlots);
    setAvailableTimeTZ(timeSlots);
  };
  const getTimeSlots = (startTime, endTime, slotDuration, currentDate) => {
    const timeSlots = [];
    const originaltimeSlots = [];

    const startDateTime = moment(
      `${currentDate} ${startTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const endDateTime = moment(
      `${currentDate} ${endTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    let currentTime = startDateTime;

    while (currentTime.isSameOrBefore(endDateTime)) {
      const currentTimeWithTZ = moment.tz(
        currentTime.format("YYYY-MM-DDTHH:mm:ss"),
        userAvailTZ?.settings?.timezone
      );

      originaltimeSlots.push(
        moment(currentTimeWithTZ).format("YYYY-MM-DD HH:mm:ss")
      );
      timeSlots.push(
        moment(currentTimeWithTZ).tz(timezone).format("YYYY-MM-DD HH:mm:ss")
      );
      currentTime.add(slotDuration, "minutes");
    }
    originaltimeSlots.pop();
    timeSlots.pop();
    return {
      originaltimeSlots,
      timeSlots,
    };
  };

  const convertTimesToSelectedTimezone = (selectedTimezone) => {
    setTimezone(selectedTimezone.value);
    setTimezoneName(selectedTimezone.label);
    setAvailableTimeTZ(
      availableTime?.map((time) => {
        const currentTimeWithTZ = moment.tz(
          moment(time).format("YYYY-MM-DDTHH:mm:ss"),
          userAvailTZ?.settings?.timezone
        );

        return moment(currentTimeWithTZ)
          .tz(selectedTimezone.value)
          .format("YYYY-MM-DD HH:mm:ss");
      })
    );
  };
  const minDate = () => {
    var currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
  };

  const titleDisabled = () => {
    return ({ date, view }) => {
      return isExcludedDate(date);
    };
  };

  const customStyles = {
    option: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: "left",
    }),
    placeholder: (provided) => ({
      ...provided,
      textAlign: "left",
      fontSize: 14,
    }),
  };

  const handleNextStep = () => {
    if (Object.values(errors).every((error) => !error)) {
      setStep((prevStep) => prevStep + 1);
    } else {
      setErrors({ ...errors, general: "Please fill all the required fields" });
    }
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const isAllWeekUnavailable = () => {
    let data = LoginData;
    const role = LoginData?.roles[0]?.name;
    let allNull = false;
    if (role === "taxpro") {
      const objData = data?.availability?.time_ranges;
      allNull = Object.values(objData).every((value) => value === null);
    } else if (role === "customer") {
      const objData = data?.lead?.[0]?.userpro?.user?.availability?.time_ranges;
      allNull = Object.values(objData).every((value) => value === null);
    }

    if (allNull) {
      return true;
    }
    return false;
  };
console.log("###@@");
  // Function to check if a time slot is within a booked range
  function isTimeSlotBooked(slot, appointment) {    
    const slotTime = new Date(slot).getTime();
    const appointmentStartTime = new Date(convertTimeIntoSselectedTZ(
      appointment?.timezone,
      timezone,
      moment.utc(appointment.start_date))).getTime();
    const appointmentEndTime = new Date(convertTimeIntoSselectedTZ(
      appointment?.timezone,
      timezone,
      moment.utc(appointment.end_date))).getTime();
    
    // Check if the slot time is within the range of the appointment
    if(
      slotTime >= appointmentStartTime && slotTime <= appointmentEndTime

    ){
      return slot
    }
  }
const availableTimeTZ2=()=>{
  const existingAppointments = allAppointments.data;
  var timezoneFinal=availableTimeTZ
  // Filter out any time slot that falls within any existing appointment range
  timezoneFinal = timezoneFinal.filter(slot => {
    return !existingAppointments.some(appointment => isTimeSlotBooked(slot, appointment));
  });

 
  return timezoneFinal
}
  return (
    <form className="appointmentsform needs-validation" onSubmit={handleSubmit}>
      <div className="row">
        {message.show ? <Message message={message} /> : <></>}
      </div>

      {step === 1 && (
        <>
          <div className="row">
            <div className="col-md-6 mb-3">
              <Input
                type="text"
                value={reason}
                name="reason"
                placeholder="Reason (title)"
                fn={setReason}
                hasValidationError={errors.reason}
                required={true}
                showLabel={false}
              />
            </div>
            <div className="col-md-6 mb-3">
              <Select
                value={type}
                name="type"
                placeholder="Type of appointment"
                fn={setType}
              >
                {appointmentTypes?.map((t) => (
                  <option key={t.id} value={t.id}>
                    {t.name}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 mb-3">
              <TextArea
                inputName="form-control"
                defaultValue={details}
                placeholder="Details"
                name="details"
                fn={setDetails}
                row="6"
                required={false}
                showLabel={false}
              />
            </div>
          </div>
          {users && users.length > 0 && (
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="clearfix">
                  <ReactSelect
                    id="users"
                    className="p-0 basic-single"
                    placeholder="Select User"
                    styles={customStyles}
                    closeMenuOnSelect={true}
                    options={users?.map((u) => ({
                      value: u.id,
                      label: `${u.firstname} ${u.lastname}`,
                    }))}
                    onChange={handleUserSelect}
                    value={
                      selectedUsers.length === 0
                        ? null
                        : {
                            value: selectedUsers[0],
                            label:
                              users.find((user) => user.id === selectedUsers[0])
                                ?.firstname +
                              " " +
                              users.find((user) => user.id === selectedUsers[0])
                                ?.lastname,
                          }
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-12 mb-3">
              <input
                className="form-check-input me-2"
                id="flexCheckChecked"
                type="checkbox"
                onChange={(e) => setVideoCheck(e.target.checked)}
                checked={videoCheck}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked">
                Video Appointment
              </label>
            </div>
          </div>
          <Button
            mainClass={`btn btn-outline-primary${
              !type ||
              !reason ||
              !details ||
              (!LoginData.isCustomer && selectedUsers.length < 1)
                ? " disabled"
                : ""
            } px-4`}
            callback={handleNextStep}
            buttonText="Next"
          />
        </>
      )}
      {step === 2 && (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="form-floating BookAppointmentForm__calendar">
                <Calendar
                  className="border-0 bg-white"
                  tileClassName=""
                  value={startDate}
                  onChange={qaMode!=='on' ?haandleChange:qaHandleChange}
                  tileDisabled={ qaMode!=='on' &&titleDisabled()}
                  minDate={qaMode!=='on' && minDate()}
                  // maxDate={maxDate()}
                  next2Label={null}
                  prev2Label={null}
                  formatShortWeekday={(locale, date) =>
                    ["S", "M", "T", "W", "T", "F", "S"][date.getDay()]
                  }
                  showFixedNumberOfWeeks={true}
                />
              </div>
            </div>
            <div className="col-md-6 mt-3 mt-md-0">
              <ReactSelect
                id="users"
                className="p-0 basic-single"
                placeholder="Select User"
                styles={customStyles}
                closeMenuOnSelect={true}
                // options={americanTimeZones}
                options={[
                  ...topTimezones?.map((v) => ({
                    value: v,
                    label: timezoneFormatter(v),
                  })),
                  ...americanTimeZones?.map((v) => ({
                    value: v,
                    label: timezoneFormatter(v),
                  })),
                ]}
                onChange={(v) => convertTimesToSelectedTimezone(v)}
                value={{
                  value: timezone,
                  label: `${timezoneFormatter(timezoneName)} (${convertTime(
                    timezone,
                    "h:mm A"
                  )})`,
                }}
              />
              <ul
                className="w-100 list-group list-group-flush mt-3"
                style={{ height: 216 }}
              >
                {isAllWeekUnavailable() &&
                  LoginData?.roles[0]?.name === "customer" && (
                    <div class="alert alert-muted">
                      Your Tax Professional does not have any Availability right
                      now
                    </div>
                  )}
                {isAllWeekUnavailable() &&
                  LoginData?.roles[0]?.name === "taxpro" && (
                    <div class="alert alert-danger">
                      You do not have any availability
                    </div>
                  )}
                <Scrollbar width={3} className="w-100 h-100">

                  {availableTimeTZ2()?.map((time, index) => (
                    <li
                      key={index}
                      className={`list-group-item mt-2 py-1 cursor-pointer${
                        startDate &&
                        moment(time).hours() === startDate.getHours() &&
                        moment(time).minutes() === startDate.getMinutes()
                          ? " bg-primary text-white"
                          : " bg-light text-black"
                      }`}
                      onClick={() => {
                        const newStartDate = moment(time);
                        newStartDate.hours(moment(time).hours());
                        newStartDate.minutes(moment(time).minutes());
                        setStartDate(newStartDate.toDate());
                      }}
                    >
                      {moment(time).format("h:mm A")}
                    </li>
                  ))}
                </Scrollbar>
              </ul>
            </div>
            {/* {!isAllWeekUnavailable() && ( */}
            <div className="col-md-12 my-3">
              <div className="row justify-content-center">
                {durationOptions?.map((option) => (
                  <div className="col-md-3" key={option.id}>
                    <Checkbox
                      type="radio"
                      wrapperClass="text-start m-0"
                      name="durationRadios"
                      disabled={isAllWeekUnavailable()}
                      state={duration}
                      value={option.id}
                      label={option.name}
                      fn={(e) => {
                        setDuration(e);
                        haandleDurationChange(e);
                      }}
                      id={`ad-radio-${option.id}`}
                      required={true}
                    />
                  </div>
                ))}
              </div>
            </div>
            {/* )} */}
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Button
              mainClass="btn btn-outline-primary me-3"
              callback={handlePreviousStep}
              buttonText="Previous"
            />
            <Button
              disabled={isAllWeekUnavailable()}
              buttonRef={button}
              mainClass={`btn btn-primary${
                Object.values(errors).indexOf(true) > -1 ||
                !type ||
                !reason ||
                !details ||
                !timezone ||
                !startDate ||
                (!LoginData.isCustomer && !selectedUsers)
                  ? " disabled"
                  : ""
              }`}
              buttonText="Create Appointment"
            />
          </div>
        </>
      )}
      {errors.general && errors.general !== "" && (
        <div className="row">
          <div className="mt-3">
            <div className="alert alert-danger" role="alert">
              {errors.general}
            </div>
          </div>
        </div>
      )}
    </form>
  );
}
