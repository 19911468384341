/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useLocation } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import {
	BASEURL,
	useAutoReplyHook,
	moneyFormatter,
	getJobStatus,
	getJobStatusClass,
} from '../../../utilites';
import Header from '../components/Header';
import Preloader from '../components/Preloader';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../Store/action';
import moment from 'moment';
import Message from '../../../components/forms/fields/Message';
import UserAvatar from '../../../components/UserAvatar';
import { INVOICE_ACTION_TYPE } from '../../../Store/invoice/Type';
import Modal from '../../../components/Dashboard/Modal';
import JobRequestForm from '../../../components/forms/JobRequestForm';
import { JOBS_ACTION_TYPE } from '../../../Store/jobs/Type';

const Contract = () => {
	// ******************************
	// HOOKS FOR GETTING INVOICES
	// ******************************
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const params = useLocation();
	const handleAutoReply = useAutoReplyHook(dispatch);
	const [
		{ personalConnections },
		{ services ,projects},
		{ LoginData },
		{
			createCustomer,
			createCustomerMessage,
			createInvoice,
			createInvoiceMessage,
			createSubscription,
			createSubscriptionMessage,
			invoices,
		},
	] = useSelector((state) => [state.firebase, state.dashboard, state.user, state.invoice]);
	const [jobData, setJobData] = useState();
	const [agreement, setAgreement] = useState(false);
	const [message, setMessage] = useState({ show: false });
	const lead = params?.state?.projects?.data?params?.state?.projects?.data?.[0]:projects?.data?.[0]
	
	const [selectedJob, setSelectedJob] = useState();
	let paidAmount = 0;
	let remainingAmount = 0;
	jobData?.invoices?.map((data) =>
		data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)))
	);
	jobData?.invoices?.map((data) => (remainingAmount = remainingAmount + parseFloat(data.amount)));
	let status = jobData?.invoices?.map((data) => {
		let paidAmount = 0;
		data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)));
		if (parseInt(data?.amount) - paidAmount <= 0) {
			return true;
		} else {
			return false;
		}
	});
	const invoiceName = services?.find((data) => data?.id === jobData?.service_id)?.name;
	const paymentDueDate = moment(jobData?.payment_due_date);
	const dueDate = moment(paymentDueDate).subtract(2, 'days')  < moment() ? moment().add(5, 'days') : paymentDueDate;
	const formattedDate = moment(dueDate).format('MM/DD/YYYY');
	console.log('formattedDate', formattedDate);
	// ******************************
	// RESET STATES
	// ******************************


	useEffect(() => {		
		if(!params?.state?.projects?.data){
			dispatch(allActions.dashboard.getProjects());
		}
	}, [dispatch]);

	useEffect(() => {
		reset_states();
		dispatch({ type: INVOICE_ACTION_TYPE.invoiceStatusIdle });
	}, [params?.state?.projects?.data]);

	// ******************************
	// SET VALUES
	// ******************************

	// ******************************
	// HOOKS FOR GETTING INVOICES
	// ******************************
	useEffect(() => {
		if (!invoices?.status && invoices?.message !== 'No customer found') {
			setMessage({
				show: true,
				type: 'Error',
				text: invoices?.message,
			});
			dispatch(allActions.global.Loader(false));
		}
		if (invoices?.status === true && createInvoice === true) {
			dispatch(allActions.global.Loader(false));
			openInvoice();
		}
	}, [invoices, invoices?.status]);

	// ******************************
	// HOOKS FOR GET JOB
	// ******************************
	useEffect(() => {
		getJobData();
	}, [id, navigate]);
	const getJobData = () => {
		axios
			.get(`${BASEURL}/api/jobs/${id}`)
			.then((res) => {
				let myJobData = res.data?.data?.jobs?.find((data) => data.uuid === id);
				if (myJobData) {
					myJobData.userpro = res.data?.data.userpro;
					setJobData(myJobData);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	console.log("MY JOB");
	// ******************************
	// HOOKS FOR CREATE CUSTOMER
	// ******************************
	useEffect(() => {
		if (!createCustomer) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createCustomerMessage,
			});
		}
		if (createCustomer === true) {
			handleGenerateInvoice();
		}
	}, [createCustomer, createCustomerMessage]);
	// ******************************
	// HOOKS FOR CREATE SUBSCRIPTION
	// ******************************
	useEffect(() => {
		if (!createSubscription) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createSubscriptionMessage,
			});
		}
		if (createSubscription === true) {
			setMessage({
				show: true,
				type: 'Success',
				text: 'Generate Invoice Successfully!',
			});
		}
	}, [createSubscription, createSubscriptionMessage, dispatch]);

	// ******************************
	// HOOKS FOR ADD INVOICES
	// ******************************
	useEffect(()=>{
     if(!lead){
		setMessage({
			show: true,
			type: 'Error',
			text: 'No project  data found',
		});
	 }else{
		setMessage({
			show: false,
		});
		dispatch(allActions.global.Loader(false));
	 }
	},[lead])

	useEffect(() => {
		if (!createInvoice) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
		}
		let invoiceMessage = 'Generate Invoice Successfully!';
		if (createInvoice === true) {
			if (jobData?.invoice_type === 'subscription') {
				let subscriptionObj = {
					user_id: lead?.leads?.user?.id,
					start_date: moment.utc(formattedDate).add(1, 'month').format('MM/DD/YYYY'),
					description: invoiceName ? invoiceName : 'Others',
					amount: jobData?.cost,
					iterations: jobData?.invoice_data?.deduction - 1,
					job_id: jobData?.id,
					pro_id: LoginData?.lead?.[0]?.pro_id,
				};
				dispatch(allActions.invoice.createSubscription(subscriptionObj));
			} else {
				setMessage({
					show: true,
					type: 'Success',
					text: invoiceMessage,
				});
			}
		}
	}, [createInvoice, createInvoiceMessage]);

	const handleJobAgree = () => {
		dispatch(allActions.global.Loader(true));
		axios
			.put(`${BASEURL}/api/jobs/agree/${id}`)
			.then((res) => {
				handleAutoMessaging();
				reset_states();
				getJobData();
				if (LoginData?.customers?.length === 0) {
					handleGenerateCustomer();
				} else {
					handleGenerateInvoice();
				}
			})
			.catch((err) => {
				dispatch(allActions.global.Loader(false));
				console.log(err);
			});
	};
	const handleGenerateCustomer = () => {
		let customerObj = {
			user_id: lead?.leads?.user?.id,
			email: lead?.leads?.user?.email,
			phone: lead?.leads?.phone,
			name: lead?.leads?.user?.firstname + ' ' + lead?.leads?.user?.lastname,
		};
		dispatch(allActions.invoice.createCustomer(customerObj));
	};
	console.log('SUBSCRIPTION>>>', lead);
	const handleGenerateInvoice = () => {
		if (!jobData?.id) {
			dispatch(allActions.global.Loader(false));
			return setMessage({
				show: true,
				type: 'Error',
				text: 'Job data not found',
			});
		}
		dispatch(
			allActions.invoice.createInvoice(
				{
					user_id: lead?.leads?.user?.id,
					dueDate: formattedDate,
					name: invoiceName ? invoiceName : 'Others',
					amount: jobData?.invoice_data?.remainingAmount
						? (jobData?.cost - jobData?.invoice_data?.remainingAmount).toFixed(2)
						: jobData?.cost,
					job_id: jobData?.id,
					pro_id: LoginData?.lead?.[0]?.pro_id,
				},
				jobData?.leads?.uuid
			)
		);
	};

	const reset_states = () => {
		dispatch(allActions.invoice.resetCreateCustomer());
		dispatch(allActions.invoice.resetCreateInvoice());
		dispatch(allActions.invoice.resetcreateSubscription());
	};

	const handleAutoMessaging = () => {
		handleAutoReply({
			personalConnections,
			LoginData,
			receiver: LoginData?.lead[0]?.userpro?.user,
			type: 'simple',
			encodedString: `<p>I have successfully signed the <a href="${window.location.origin}/dashboard/contract/${id}" target="_blank" >Contract</a>.</p>`,
		});
	};

	const openInvoice = () => {
		// filter Invoice
		let invoiceGenerated = invoices?.data?.find(
			(data) => Number(data?.job_id) === Number(jobData?.id)
		);
		console.log('Recent Generated Invoice>>>>', invoiceGenerated);
		if (invoiceGenerated) {
			navigate(`/dashboard/invoices/${invoiceGenerated.stripe_invoice_id}`, {
				state: { navigate: 'projects', message: 'Invoice Generated Successfully!' },
			});
		}
		reset_states();
	};
	if (jobData) {
		return (
			<div className="clearfix">
				<Header name="Contract" />
				{message.show ? <Message message={message} /> : <></>}
				<div className="container page">
					<div className="clearfix pt-4 mb-4">
						<div className="card border-1 border-light rounded p-4 mb-4">
							<div className="card-header d-flex justify-content-between bg-white border-0">
								<h3 className="card-title text-primary fw-bold">Accountant</h3>
								<div className="card-actions">
									{/*<span className='btn btn-sm text-primary'>+</span>*/}
									<span className="btn btn-sm text-primary border-0 disabled">
										<BsThreeDotsVertical />
									</span>
								</div>
							</div>
							<div className="card-body">
								<div className="d-flex align-items-center">
									<div>
										<UserAvatar
											avatar={jobData.userpro?.user?.avatar}
											alt={`${jobData.userpro?.user?.firstname} ${jobData.userpro?.user?.lastname}`}
											className="img-fluid object-fit-cover rounded-circle"
											style={{ height: 40 }}
										/>
									</div>
									<div className="text-dark m-0 ms-2 fw-bold">
										{`${jobData.userpro?.user?.firstname} ${jobData.userpro?.user?.lastname}`}
										<div className="text-secondary-600 mb-0 fw-light" style={{ fontSize: 12 }}>
											Tax pro
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="card border-1 border-light rounded p-4 mb-4">
							<div className="card-header d-flex justify-content-between bg-white border-0">
								<h3 className="card-title text-primary fw-bold">Terms</h3>
								<div className="card-actions">
									{/*<span className='btn btn-sm text-primary'>+</span>*/}
									<div className="dropdown-center">
										<button
											type="button"
											className={`btn btn-sm text-primary border-0`}
											data-bs-toggle="dropdown"
											aria-expanded="false"
										>
											<BsThreeDotsVertical className="" />
										</button>
										<ul className="dropdown-menu border-light">
											{LoginData.isTaxPro && (
												<li>
													<Link
														className={`dropdown-item ${jobData.status_id > 1 ? 'disabled' : ''}`}
														to={`/dashboard/editContract/${jobData.uuid}`}
													>
														Edit
													</Link>
												</li>
											)}
											<li className={jobData.status_id === 0 ? 'd-none' : ''}>
												<Link
													to="#"
													className={`dropdown-item text-danger`}
													data-bs-toggle="modal"
													data-bs-target="#jobCanelRequestForm"
												>
													Request to Cancel
												</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="row mb-3">
									<div className="col-3">Service type</div>
									<div className="col-9">{jobData.service?.name}</div>
								</div>
								<div className="row mb-3">
									<div className="col-3">Status</div>
									<div className="col-9">
										<span className={`badge bg-${getJobStatusClass(jobData.status_id)}`}>
											{getJobStatus(jobData.status_id)}
										</span>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-3">Completion date</div>
									<div className="col-9">
										{new Date(jobData.completed_at)?.toLocaleString('en-US', {
											month: 'long',
											day: 'numeric',
											year: 'numeric',
										})}
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-3">Total Cost</div>
									<div className="col-9">
										$
										{jobData.cost?.toLocaleString('en-US', {
											minimumFractionDigits: 2,
										})}
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-3">Payment Type</div>
									<div className="col-9">
										{jobData.invoice_type === 'partial' ? (
											<span>
												Partial Payment of{' '}
												<strong>
													{jobData.invoice_data.deductionType === '' && '$'}
													{jobData.invoice_data.deduction}
													{jobData.invoice_data.deductionType}
												</strong>{' '}
												upfront.
											</span>
										) : (
											<span>Full Payment Upfront</span>
										)}
									</div>
								</div>
								{jobData.invoice_type === 'partial' && (
									<div className="row mb-3">
										<div className="col-3">Balance Due</div>
										<div className="col-9">
											{moneyFormatter(jobData.cost - paidAmount)}
											{` `}
											{jobData.cost - paidAmount === 0 ? (
												<span className="badge bg-dark">Paid</span>
											) : (
												<span className="badge bg-secondary-600">Due</span>
											)}
										</div>
									</div>
								)}
								<div className="row">
									<div className="col-3">Description</div>
									<div className="col-9">{jobData.description}</div>
								</div>
							</div>
						</div>
						<>
							<div className="clearfix p-2 mt-4">
								<div className="form-check">
									<input
										type="checkbox"
										name="agreed"
										className="form-check-input"
										id="agreed2"
										value="1"
										disabled={LoginData.isCustomer ? false : true}
										checked={jobData?.confirmed_at && jobData.status_id !== 0 ? true : agreement}
										onChange={(e) =>
											jobData?.confirmed_at && jobData.status_id !== 0
												? {}
												: setAgreement(e.target.checked)
										}
									/>
									<label className="form-check-label" htmlFor="agreed2">
										Yes, I understand and agree to the Uncle Kam Terms of Service, including the{' '}
										<Link to="/user-agreement">User Agreement</Link> and{' '}
										<Link to="/privacy-policy">Privacy Policy</Link>.
									</label>
								</div>
							</div>
							<div className="clearfix p-2 mt-4 d-flex justify-content-end">
								<span className="btn btn-outline-primary me-3" onClick={() => navigate(-1)}>
									&larr; Go Back
								</span>
								{LoginData.isCustomer && (
									<>
										{(jobData?.confirmed_at === null || jobData.status_id === 0) && (
											<Link
												data-bs-toggle="modal"
												data-bs-target="#jobCanelRequestForm"
												className="btn btn-outline-primary me-3"
												onClick={() => {
													setSelectedJob(jobData);
													setMessage({ show: false });
													dispatch({ type: JOBS_ACTION_TYPE.cancelJobRequestIdle });
												}}
											>
												Request Modification
											</Link>
										)}
										{jobData?.confirmed_at &&
										jobData.status_id !== 0 &&
										jobData?.invoices?.length <= 0 ? (
											<button
												className={`btn btn-primary`}
												onClick={() => {
													if (jobData?.confirmed_at || jobData?.invoices?.length <= 0) {
														dispatch(allActions.global.Loader(true));
														handleGenerateInvoice();
													}
												}}
											>
												Pay Balance
											</button>
										) : (
											<button
												className={`btn btn-primary ${
													agreement
														? ''
														: status?.indexOf(false) !== -1 && !agreement
														? ''
														: 'disabled'
												}`}
												onClick={() => {
													if (jobData.status_id === 0) {
														handleJobAgree();
													} else {
														navigate(
															`/dashboard/invoices/${
																jobData?.invoices?.[status?.indexOf(false)]?.stripe_invoice_id
															}`,
															{ state: { navigate: 'projects' } }
														);
													}
												}}
											>
												Continue
											</button>
										)}
									</>
								)}
							</div>
						</>
					</div>
				</div>
				<Modal
					title="Job Modification Request"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="jobCanelRequestForm"
					showBtn={false}
					btnClass="jobCanelRequestModalClose"
				>
					<JobRequestForm
						type={0}
						triggerClass=".jobCanelRequestModalClose"
						setMessage={setMessage}
						uuid={selectedJob?.uuid}
						receiver={jobData?.userpro?.user}
						sender={LoginData}
					/>
				</Modal>
			</div>
		);
	} else {
		return <Preloader />;
	}
};

export default Contract;
