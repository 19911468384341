/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQueryParams, getQueryParam, BASEURL } from '../../../utilites';
import Header from '../../dashboard/components/Header';
//import Modal from "../../../components/Dashboard/Modal";
import Footer from '../../dashboard/components/Footer';
import allActions from '../../../Store/action';
import Pagination from '../../../components/Pagination';
import { FaPlay } from "react-icons/fa";
// CSS
import 'react-datepicker/dist/react-datepicker.css';
import DropDown from '../../../components/forms/fields/DropDown';
import { MdError } from "react-icons/md";
import Loader from '../../../components/Loader';

const Recordings = () => {
    const [selectedTaxPro, setSelectedTaxPro] = useState();
    const dispatch = useDispatch();

    const [{ user, dashboard }, { getRecordings }, { invoices }, { loginStatus }] =
        useSelector((state) => [state, state.recordings, state.invoice, state.user]);
  

    // Tax Pro List For Admin & Account Manager
    const taxProList = dashboard?.allUsers?.data
        ?.filter((data) => data?.roles?.[0]?.display_name === 'Tax Pro')
        ?.map((data) => ({
            value: data.firstname + ' ' + data.lastname,
            label: data.firstname + ' ' + data.lastname,
            id: data?.userpro?.id,
            amount: data?.userpro?.total_balance,
            userId:data.id
        }));

    console.log("TPL@@", dashboard)

    const [selectedIndex, setSelectedIndex] = useState(-1);
    // Pagination
    const [perPage, setPerPage] = useState(getQueryParam('per_page') || 10);
    const [page, setPage] = useState(getQueryParam('page') || 1);
    const [url, seturl] = useState();
    const [playResponse, setPlayResponse] = useState();

    // GET JOB OPTIONS
    const jobsOptions = dashboard?.services?.map((data) => {
        return {
            value: data?.id,
            label: data?.name,
        };
    });
    jobsOptions.push({
        value: 'tgp-custom-option',
        label: 'Others',
    });

    useEffect(() => {
        if (selectedTaxPro?.userId) {
            dispatch(allActions.recordings.getRecordings(selectedTaxPro?.userId))
        } else {
            dispatch(allActions.recordings.getRecordings())
        }
    }, [selectedTaxPro]);

    // GET DATA
    useEffect(() => {
        // FOR ADMIN
        if (loginStatus && !user?.isRegular && !user?.isTaxPro) {
            dispatch(allActions.dashboard?.getAllUsers('', ''));
        }
        // FOR ACCOUNT MANAGER
        if (loginStatus && user?.isAccountManager) {
            dispatch(allActions.dashboard?.getAllUsers('', ''));
        }
    }, [window.location.href]);

    const moveTo = (page) => {
        setPage(page);
        // Set query param
        setQueryParams('page', page);
    };

    const changePerPage = (perPage) => {
        setPerPage(perPage);
        // Set query param
        setQueryParams('per_page', perPage);
    };


    const playRec = async (callId,rec) => {

        if(callId.startsWith('CON')){
            //  for vonage
            const response = await fetch(`${BASEURL}/api/vonage/recording-download?recording_id=${rec}`);            
						const audioUrl = URL.createObjectURL(await response.blob());
						setPlayResponse(response);
						seturl(audioUrl);
        }
            else{
        // for twilio 
        const authHeader =
					'Basic ' +
					btoa(
						`${process.env.REACT_APP_TWILIO_VOICE_ACCOUNT_SID}:${process.env.REACT_APP_TWILIO_AUTH_TOKEN}`
					);

				const response = await fetch(
					`https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_VOICE_ACCOUNT_SID}/Calls/${callId}/Recordings/${rec}.jmp3`,
					{
						headers: {
							Authorization: authHeader,
						},
					}
				);
				setPlayResponse(response);
				const blob = await response.blob();
				const objectUrl = URL.createObjectURL(blob);
				seturl(objectUrl);
            }
    }
    return (
        <div className="clearfix dashboard-Page">
            <Header name="Recordings" />
            <div className="container page">
                <div className="clearfix mb-4">
                    <div className="row justify-content-center">
                        <div className="col-md-12 p-0">
                            <div className="clearfix mt-4">
                                <div className="clearfix mt-4 mb-5">
                                    <div className="row justify-content-between">
                                        <div className="col-md-10">
                                            <div className="row g-3">
                                                <div className="col-md-2">
                                                    <div className="clearfix w-100 mb-3">
                                                        {!user?.isTaxPro && (
                                                            <div className="clearfix w-100 mb-3">
                                                                <div className="clearfix">
                                                                    <DropDown
                                                                        options={taxProList}
                                                                        value={selectedTaxPro}
                                                                        setValue={setSelectedTaxPro}
                                                                        label={'Select Taxpro'}
                                                                        showLabel={false}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="clearfix">
                                <div className="card border-0">
                                    <div className="clearfix position-relative">
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead className="text-dark text-opacity-50">
                                                    <tr>
                                                        <th scope="col">ID</th>
                                                        <th scope="col">CallSid</th>
                                                        <th scope="col">recording_id</th>
                                                        <th scope="col">Audio</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getRecordings?.data?.data?.map((data, index) => {
                                                        return (
																													<tr key={index}>
																														<th scope="row">{data.id}</th>

																														<td>{data?.CallSid}</td>
																														<td>{data.recording_id}</td>
																														<td>
																															{
																																<div className="d-flex justify-content-center align-items-center">
																																	{selectedIndex === index ? (
																																		url &&
																																		playResponse?.status === 200 ? (
																																			<audio controls>
																																				<source
																																					src={url}
																																					type="audio/mpeg"
																																				/>
																																				Your browser does not
																																				support the audio element.
																																			</audio>
																																		) : playResponse?.status !==
																																				200 &&
																																		  playResponse !== undefined ? (
                                                                                                                                            <MdError size={26} color='#FF0000'/>

																																		) : (
                                                                                                                                            <Loader
                                                                                                                                            size={{ width: '2rem', height: '2rem' }}
                                                                                                                                            padding={10}
                                                                                                                                            local={true}
                                                                                                                                            loading={true}
                                                                                                                                            customBg={'rgba(0,0,0,0)'}
                                                                                                                                            viewClass="bg-transparent"
                                                                                                                                        />
																																		)
																																	) : (                                                                                                                                     
																																		<FaPlay
																																			style={{ cursor: 'pointer' }}
																																			size={28}
																																			color="green"
																																			onClick={() => {
																																				setSelectedIndex(index);
																																				setPlayResponse(undefined);
																																				seturl(undefined);
																																				playRec(
																																					data?.CallSid,
																																					data?.recording_id
																																				);
																																			}}
																																		/>
																																	)}
																																</div>
																															}
																														</td>
																													</tr>
																												);
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <Pagination
                                        pageCount={Math.ceil(invoices.count / perPage)}
                                        currentPage={parseInt(page)}
                                        setCurrentPage={moveTo}
                                        perPage={perPage}
                                        setPerPage={(pc) => {
                                            changePerPage(pc);
                                            // Move to first page
                                            if (page !== 1) moveTo(1);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Recordings;
