import axios from 'axios';
import { useState, useRef, useEffect } from 'react';
import { BsSignStopFill } from 'react-icons/bs';

import Select from './fields/Select';
import Button from './fields/Button';

import { BASEURL } from '../../utilites';

export default function ReAssignTaxProForm({ data, lead, reload, setAlert }) {
	const [pro, setPro] = useState();
	const button = useRef();
	const [errors, setErrors] = useState({
		pro: false,
		general: '',
	});

	useEffect(() => {
		setPro(lead?.userpro?.id || '');
	}, [lead]);

	// function to handle form submission
	const handleSubmit = (e) => {
		e.preventDefault();
		button.current.disabled = true;
		if (errors.general !== '') setErrors({ errors, general: '' });
		axios
			.put(`${BASEURL}/api/leads/update-pro`, {
				proId: pro,
				leadId: lead.id,
			})
			.then(() => {
				button.current.disabled = false;
				reload && reload();
				// Close Modal
				document.querySelector('.reAssignTaxProModalClose').click();
				setAlert('Tax professional has been updated successfully!');
			})
			.catch((error) => {
				button.current.disabled = false;
				console.error(error.message);
				// Set general error
				if (errors.general === '')
					setErrors({ errors, general: error?.response?.data?.error || error.message });
			});
	};

	return (
		<form className="userform needs-validation" onSubmit={handleSubmit}>
			{data && (
				<div className="row">
					<div className="mb-3">
						<Select value={pro} name="taxpro" placeholder="Tax professional" fn={setPro}>
							{data?.map((r) => (
								<option key={r.userpro.id} value={r.userpro.id}>
									{`${r?.firstname} ${r?.lastname}`}
								</option>
							))}
						</Select>
					</div>
				</div>
			)}
			{errors.general && errors.general !== '' && (
				<div className="row">
					<div className="mb-3">
						<div className="alert alert-danger" role="alert">
							{errors.general}
						</div>
					</div>
				</div>
			)}
			{parseInt(pro) !== lead?.userpro?.id && (
				<div className="clearfix">
					<div className="alert alert-warning" role="alert">
						<p>
							<BsSignStopFill className="fs-1" />
						</p>
						<p>
							<strong>Wait a minute!</strong> You are about to reassign this lead to a different tax
							professional. Any pending or active job between this lead and the current Tax
							Professional will be marked canceled.
						</p>
						<p>Are you sure you want to continue?</p>
					</div>
				</div>
			)}
			<Button
				buttonRef={button}
				mainClass={`btn btn-danger btn-lg px-4${
					Object.values(errors).indexOf(true) > -1 || parseInt(pro) === lead?.userpro?.id
						? ' disabled'
						: ''
				}`}
				wrapperClass="clearfix w-75 mx-auto d-grid text-center mt-3"
				buttonText="Change"
			/>
		</form>
	);
}
