import { Link } from "react-router-dom";
import Modal from "../../Dashboard/Modal";
import DialpadModal from "./DialpadModal";


export default function VonageAudioCallModal({selectedReceiver,hangUp,callData}) {


    return (
        
        <Modal
            title={"Audio Call"}
            bodyClass="p-4"
            btnText={"Audio Call"}
            trigger="VonageAudioCallModal"
            showBtn={false}
            size={"sm"}
            className='centerclass'
            close={false}
            width={'400px'}
            sideClose={false}
            backdrop={false} // Prevent clicking on the backdrop from closing the modal
            keyboard={false}
        >
            <div className="container ">
                <div className="row ">
                    <div className="col-md-12">
                        <div className=" border-0">
                            <div className="">
                                <div className="text-center mb-4">
                                <h2>{callData?.status}</h2>
                                <p className="text-muted">Calling {selectedReceiver?.firstname + " " + selectedReceiver?.lastname}</p>
                                </div>
                                <DialpadModal hangUp={hangUp} connection={true} />
                                <Link
                                        data-bs-toggle="modal"
                                        data-bs-target="#VonageAudioCallModal"
                                        aria-controls="VonageAudioCallModal"
                                        className="VonageAudioCallModalButton"
                                    >
                                    </Link>
                                <div className="text-center">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
