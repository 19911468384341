/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import { BsPersonPlus } from 'react-icons/bs';
import GenerateInvoice from '../../../../components/forms/Modal/InvoiceModal';
// import Message from '../../../../components/forms/fields/Message';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import allActions from '../../../../Store/action';
import moment from 'moment';
import { FaTrashAlt } from 'react-icons/fa';
import { FaEdit } from 'react-icons/fa';
import Message from '../../../../components/forms/fields/Message';

const CustomerInvoices = ({ activeLead, activeJob, selectedJob,addInvoiceButton,disabaleDropDown}) => {
	const dispatch = useDispatch();
	const InvoicePeriodOptions = [
		{ value: '3', label: '3' },
		{ value: '6', label: '6' },
		{ value: '12', label: '12' },
	];
	const InvoiceOptions = [
		{ value: 'FullPayment', label: 'Full Payment' },
		{ value: 'PartialPayment', label: 'Partial Payment' },
		// { value: 'RecurringPayment', label: 'Recurring Payment' },
	];

	const [
		{ user },
		{ dashboard },
		{
			createCustomer,
			createCustomerMessage,
			invoices,
			createInvoice,
			createInvoiceMessage,
			createSubscription,
			createSubscriptionMessage,
		},
	] = useSelector((state) => [state, state, state.invoice]);
	// user.isAdmin = true;
	const [firstLoad, setFirstLoad] = useState(false);
	const [editInvoiceObj, setEditInvoiceObj] = useState({});
	const [editInvoice, setEditInvoice] = useState(false);
	const [deleteInvoice, setDeleteInvoice] = useState(false);
	const [SelectedInvoice, setSelectedInvoice] = useState('');
	const [selectedService, setselectedService] = useState('');
	const [InvoiceName, setInvoiceName] = useState('');
	const [InvoiceAmount, setInvoiceAmount] = useState('');
	const [InvoiceDueDate, setInvoiceDueDate] = useState('');
	const [InvoicePeriod, setInvoicePeriod] = useState('');
	const [message, setMessage] = useState({ show: false });
	const [outerMessage, setOuterMessage] = useState({ show: false });
	const [handler, setHandler] = useState(false);
	const [duplicateInvoiceMessage, setDuplicateInvoiceMessage] = useState(false);
	const [warningCheckBox, setWarningCheckBox] = useState(false);
	const [leadjoblist, setLeadjoblist] = useState([]);
	const [SelectedJob, setSelectedJob] = useState('');
	const [SelectedLead, setSelectedLead] = useState('');

	const leadsOptions = dashboard.leads.data
		.filter((data) => data?.uuid === activeLead?.uuid)
		?.map((data) => {
			return {
				value: data.user.id,
				label: data.user.firstname + ' ' + data.user.lastname,
				phone: data.phone,
				email: data.user.email,
				customers: data.user.customers,
				jobs: data.jobs,
				services: data.services,
			};
		});
	const selectedJobId = leadsOptions?.[0]?.jobs.find(
		(data) => data?.service_id === selectedService?.value
	)?.id;


	useEffect(()=>{
			if(addInvoiceButton){
			if (leadsOptions?.[0]){			
			let jobsList = leadsOptions?.[0]?.jobs
			?.filter((data) =>(data?.status_id === 2 || data?.status_id === 3 ) && data?.id===selectedJob.id)
			.map((data) => {
				return {
					value: data?.service_id !== null ? data?.service?.name : data?.others,
					label: `${data?.service_id !== null ? data?.service?.name : data?.others}  (${data.id})`,
					job_id: data.id,
					invoices: data?.invoices,
				};
			});
           setSelectedLead(leadsOptions?.[0])
		   setLeadjoblist(jobsList)
		   setSelectedJob(jobsList[0])
		   setInvoiceName(jobsList[0].value)
		}
	}	
		},[dashboard.leads.data])	


	// GET JOB OPTIONS
	const result = [];
	leadsOptions?.[0]?.services?.map(
		(service) =>
			leadsOptions?.[0]?.jobs?.map((job) => job.service_id).includes(service.id) &&
			result.push(service)
	);
	const jobsOptions = result?.map((data) => {
		return {
			value: data?.id,
			label: data?.name,
		};
	});

	jobsOptions.push({
		value: 'tgp-custom-option',
		label: 'Others',
	});

	// Set Invoice Name on Basis of selected service
	useEffect(() => {
		if (selectedService?.value !== 'tgp-custom-option') {
			setInvoiceName(
				dashboard?.services.find((data) => {
					return data.id === selectedService?.value;
				})?.name
			);
		} else {
			setInvoiceName('');
		}
	}, [selectedService]);

	console.log('InvoiceName', InvoiceName);
	// UseEffect To Reset Values
	useEffect(() => {
		reset_message();
	}, []);

	useEffect(() => {
		if (activeJob) {
			dispatch(allActions.invoice.getJobInvoices(activeJob?.uuid));
		}
		if (invoices?.status === 'idle' && activeLead?.user?.customers?.[0]?.customer_stripe_id) {
			dispatch(allActions.global.Loader(true));
			if (!activeJob) {
				dispatch(allActions.invoice.getLeadInvoices(activeLead?.uuid,selectedJob?.uuid));
			}
		}
		if (!user.isRegular) {
			dispatch(allActions.dashboard.getLeads('', ''));
		}
	}, []);
	console.log('CUSTOMER STRIPE ID:', activeLead?.user?.customers?.[0]?.customer_stripe_id);
	// *********************************************
	// Handle Loading For Fetching Data on Load
	// *********************************************
	useEffect(() => {
		if (invoices.status === 'idle') {
			if (activeLead?.user?.customers?.[0]?.customer_stripe_id) {
				if (!activeJob) {
					dispatch(allActions.invoice.getLeadInvoices(activeLead?.uuid,selectedJob?.uuid));
				}
			}
		}
	}, [invoices.status]);

	useEffect(() => {
		if (invoices?.status === 'idle') {
			dispatch(allActions.global.Loader(true));
		}
		if (invoices?.status === true && !firstLoad) {
			setOuterMessage({ show: false });
			// dispatch(allActions.global.Loader(false));
			setFirstLoad(true);
		}
	}, [invoices]);

	useEffect(() => {
		if (!user.isRegular ? dashboard.leads.loaded : true) {
			dispatch(allActions.global.Loader(false));
		}
	}, [dashboard.leads.loaded]);
	// ****************************** 
	// HOOKS FOR GETTING INVOICES
	// ******************************
	console.log('invoices', invoices.status);
	useEffect(() => {
		if (!invoices?.status && invoices.message !== 'No customer found') {
			setOuterMessage({ show: false });
			dispatch(allActions.global.Loader(false));
			setOuterMessage({
				show: true,
				type: 'Error',
				text: invoices?.message,
			});
		}
		if (!invoices?.status) {
			dispatch(allActions.global.Loader(false));
		}
	}, [invoices]);
	useEffect(() => {
		if (!createCustomer && handler) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createCustomerMessage,
			});
		}
		if (createCustomer === true && handler) {
			let invoiceObj = {
				user_id: leadsOptions?.[0].value,
				dueDate: InvoiceDueDate,
				name: InvoiceName,
				amount: InvoiceAmount,
				job_id:SelectedJob?.job_id

			};
			if (selectedJobId) {
				invoiceObj.job_id = selectedJobId;
			}
			dispatch(allActions.invoice.createInvoice(invoiceObj));
		}
	}, [
		createCustomer,
		createCustomerMessage,
		InvoiceDueDate,
		InvoiceName,
		InvoiceAmount,
		selectedJobId,
	]);

	// Used for Add , Edit and delete
	useEffect(() => {
		// Show message outside of modal For Deletion Error
		if (!createInvoice && deleteInvoice && handler) {
			dispatch(allActions.global.Loader(false));
			setOuterMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
			return;
		}
		// Show Error Message in modal For Edit and Add
		if (!createInvoice && handler) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createInvoiceMessage,
			});
		}

		let invoiceMessage = editInvoice
			? 'Invoice update Successfully!'
			: deleteInvoice
			? 'Invoice delete Successfully!'
			: 'Generate Invoice Successfully!';

		if (createInvoice === true && handler) {
			if (SelectedInvoice?.value === 'RecurringPayment') {
				let subscriptionObj = {
					user_id: leadsOptions?.[0].value,
					start_date: moment.utc(InvoiceDueDate).add(1, 'month').format('MM/DD/YYYY'),
					description: InvoiceName,
					amount: InvoiceAmount,
					iterations: InvoicePeriod.value - 1,
				};
				if (selectedJobId) {
					subscriptionObj.job_id = selectedJobId;
				}
				dispatch(allActions.invoice.createSubscription(subscriptionObj));
			} else {
				if (deleteInvoice) {
					reset_states();
				} else {
					document.querySelector('.invoiceModaltoggle').click();
				}
				CloseModalOverlay();
				reset_Form();
				dispatch(allActions.global.Loader(false));
				setOuterMessage({
					show: true,
					type: 'Success',
					text: invoiceMessage,
				});
				dispatch(allActions.user.ReGetUserData());
			}
		}
	}, [
		createInvoice,
		createInvoiceMessage,
		InvoiceAmount,
		InvoiceDueDate,
		InvoiceName,
		InvoicePeriod,
		SelectedInvoice,
		deleteInvoice,
		editInvoice,
	]);
	console.log('outerMessage', SelectedInvoice?.value);
	useEffect(() => {
		if (!createSubscription && handler) {
			dispatch(allActions.global.Loader(false));
			setMessage({
				show: true,
				type: 'Error',
				text: createSubscriptionMessage,
			});
		}
		if (createSubscription === true && handler) {
			dispatch(allActions.user.ReGetUserData());
			document.querySelector('.invoiceModaltoggle').click();
			CloseModalOverlay();
			reset_Form();
			setOuterMessage({
				show: true,
				type: 'Success',
				text: 'Generate Invoice Successfully!',
			});
			dispatch(allActions.global.Loader(false));
		}
	}, [createSubscription, createSubscriptionMessage]);

	const reset_message = () => {
		console.log('reset_message');
		// it indicate we are not  deleting invoice but clearing All messages
		setDeleteInvoice(false);
		setMessage({ show: false });
		setOuterMessage({ show: false });
		reset_states();
	};
	const reset_states = () => {
		dispatch(allActions.invoice.resetCreateCustomer());
		dispatch(allActions.invoice.resetCreateInvoice());
		dispatch(allActions.invoice.resetcreateSubscription());
	};
	const reset_Form = () => {
		setHandler(false);
		setMessage({ show: false });
		setOuterMessage({ show: false });
		setSelectedInvoice('');
		setselectedService('');
		// setInvoiceName('');
		setInvoiceAmount('');
		setInvoiceDueDate('');
		setInvoicePeriod('');
		setWarningCheckBox(false)
        setDuplicateInvoiceMessage(false)
	};
	const set_EditForm = (data) => {
		// console.log(
		// 	data?.job_id,
		// 	leadsOptions?.[0]?.jobs?.map((data) => data.id)
		// );
		const formattedDate = moment(data?.due_date, 'YYYY-MM-DD').format('MM/DD/YYYY');
		document.querySelector('.invoiceModaltoggle').click();
		// setSelectedInvoice("");
		setInvoiceName(data.name);
		setInvoiceAmount(data.amount);
		// setInvoiceDueDate(moment.utc(data.created_at).format("MM/DD/YYYY"));
		setInvoiceDueDate(formattedDate);
		// setInvoicePeriod("");
		setEditInvoice(true);
		setDeleteInvoice(false);
	};
	const handleDelete = (data) => {
		dispatch(allActions.global.Loader(true));
		let invoiceObj = {
			invoiceId: data.stripe_invoice_id,
		};
		setHandler(true);
		dispatch(allActions.invoice.removeInvoice(invoiceObj, activeLead?.uuid));
	};
	const handleSubmit = (e) => {
		var duplicateInvoice=false
			// eslint-disable-next-line array-callback-return
			SelectedJob?.invoices.map((v)=>{
              if(v.amount===InvoiceAmount){
				duplicateInvoice=true
			  }
		})
		if(duplicateInvoice===true && !warningCheckBox){
			setDuplicateInvoiceMessage(true)
			e.preventDefault();
			return 
		}

		e.preventDefault();
		reset_message();
		dispatch(allActions.global.Loader(true));
		let customerObj = {
			user_id: leadsOptions?.[0].value,
			email: leadsOptions?.[0].email,
			phone: leadsOptions?.[0].phone,
			name: leadsOptions?.[0].label,
		};
		if (leadsOptions?.[0].customers.length === 0) {
			dispatch(allActions.invoice.createCustomer(customerObj, true));
		} else {
			let invoiceObj = {
				// customerId: LoginData.customers[0].customer_stripe_id,
				user_id: leadsOptions?.[0].value,
				dueDate: InvoiceDueDate,
				name: InvoiceName,
				amount: InvoiceAmount,
				job_id:SelectedJob?.job_id
			};
			if (selectedJobId) {
				invoiceObj.job_id = selectedJobId;
			}
			dispatch(allActions.invoice.createInvoice(invoiceObj, activeLead?.uuid));
		}
		setHandler(true);
	};
	const handleUpdate = (e) => {
		e.preventDefault();
		reset_message();
		dispatch(allActions.global.Loader(true));

		let invoiceObj = {
			dueDate: InvoiceDueDate,
			name: InvoiceName,
			amount: InvoiceAmount,
			invoiceId: editInvoiceObj.stripe_invoice_id,
		};
		setHandler(true);

		dispatch(allActions.invoice.updateInvoice(invoiceObj, activeLead?.uuid));
	};
	const CloseModalOverlay = () => {
		try {
			// Remove "modal-open" class from the body tag
			document.body.classList.remove('modal-open');

			// Reset the inline styles on the body tag
			document.body.style.overflow = '';
			document.body.style.paddingRight = '';

			// Remove the modal backdrop element
			var backdrop = document.querySelector('.modal-backdrop');
			if (backdrop) {
				backdrop.parentNode.removeChild(backdrop);
			}
		} catch (error) {}
	};

	return (
		<div className="clearfix mt-3">
			{addInvoiceButton &&
			<div className="d-flex flex-row w-100 justify-content-end">
										{/* <h1 className="text-primary">Invoices</h1> */}
										<div className="col-md-2">
											<Link
												to="#"
												className="btn btn-primary float-center float-md-end mt-3 mt-md-0 invoiceModaltoggle"
												data-bs-toggle="modal"
												data-bs-target="#addNewInvoiceModal"
												onClick={() => {
													setEditInvoice(false);
													// setDeleteInvoice(false);
													reset_Form();
													reset_message();
												}}
											>
												Add invoice
											</Link>
										</div>
									</div>
}
			{outerMessage.show ? <Message message={outerMessage} /> : <></>}
			{user.isTaxPro && (
				<Link
					to="#"
					className="btn btn-primary rounded-5 position-absolute top-0 start-100 translate-middle invoiceModaltoggle"
					data-bs-toggle="modal"
					data-bs-target="#addNewInvoiceModal"
					onClick={() => {
						setEditInvoice(false);
						setDeleteInvoice(false);
						reset_Form();
						reset_message();
					}}
				>
					<BsPersonPlus className="me-1" />
				</Link>
			)}
			<table className="table">
				<thead className="text-dark text-opacity-50">
					<tr>
						<th scope="col">ID</th>
						<th scope="col">Title</th>
						<th scope="col">Job Name</th>
						<th scope="col">Amount</th>
						<th scope="col">Created On</th>
						<th scope="col">Status</th>
						<th scope="col">Actions</th>
					</tr>
				</thead>
				<tbody>
					{invoices.status &&
						invoices?.data &&
						invoices?.data
							.slice()
							.reverse()
							.filter((data) => data?.customers?.user_id === activeLead?.user?.id)
							?.map((data, index) => {
								let paidAmount = 0;
								data?.payments?.map(
									(i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid))
								);
								return (
									<tr key={index}>
										<td>{data.id}</td>
										<td>
											<Link
												to={`/dashboard/invoices/${data.stripe_invoice_id}`}
												onClick={() => {
													dispatch(allActions.invoice.resetPayInvoice());
													dispatch(allActions.payment.resetAddPayment());
													dispatch(allActions.payment.resetModifyPayment());
												}}
											>
												{data.name}
											</Link>
										</td>
										<td>{data?.job?.service?.name || data?.job?.others} </td>
										<td>${data?.amount}</td>
										<td>{new Date(data.created_at).toLocaleDateString()}</td>
										<td> {parseInt(data?.amount) - paidAmount <= 0 ? 'Paid' : ' Pending'}</td>
										{!user.isTaxPro || data?.payments[0]?.amount_paid ? (
											<td className="text-end"></td>
										) : (
											<td>
												<Link
													onClick={() => {
														reset_message();
														set_EditForm(data);
														setEditInvoiceObj(data);
													}}
													className="btn btn-sm"
													to="#"
												>
													<FaEdit />
												</Link>
												<Link
													className="btn btn-sm btn-danger"
													to="#addNewInvoiceModal"
													onClick={() => {
														reset_message();
														setEditInvoice(false);
														setDeleteInvoice(true);
														handleDelete(data);
													}}
												>
													<FaTrashAlt />
												</Link>
											</td>
										)}
									</tr>
								);
							})}
					{invoices?.status !== 'idle' &&
					invoices?.data &&
					invoices?.data?.filter((data) => data?.customers?.user_id === activeLead?.user?.id)
						?.length === 0 ? (
						<tr className="text-center text-muted">
							<td colSpan={8}>You have not created any invoice yet.</td>
						</tr>
					) : (
						<></>
					)}
				</tbody>
			</table>
			<GenerateInvoice
				message={message}
				InvoiceName={InvoiceName}
				setInvoiceName={setInvoiceName}
				InvoiceDueDate={InvoiceDueDate}
				setInvoiceDueDate={setInvoiceDueDate}
				InvoicePeriod={InvoicePeriod}
				InvoiceAmount={InvoiceAmount}
				setInvoiceAmount={setInvoiceAmount}
				setInvoicePeriod={setInvoicePeriod}
				InvoiceOptions={InvoiceOptions}
				SelectedInvoice={SelectedInvoice}
				setSelectedInvoice={setSelectedInvoice}
				InvoicePeriodOptions={InvoicePeriodOptions}
				handleSubmit={handleSubmit}
				handleUpdate={handleUpdate}
				handleCancel={reset_message}
				leadsOptions={leadsOptions}
				SelectedLead={SelectedLead}
				setSelectedLead={setSelectedLead}
				editInvoice={editInvoice}
				jobInvoice={true}
				jobsOptions={jobsOptions}
				selectedService={selectedService}
				setselectedService={setselectedService}
	            SelectedJob={SelectedJob}
				setSelectedJob={setSelectedJob}
				duplicateInvoiceMessage={duplicateInvoiceMessage}
				warningCheckBox={warningCheckBox}
				setDuplicateInvoiceMessage={setDuplicateInvoiceMessage}
				setWarningCheckBox={setWarningCheckBox}
				leadjoblist={leadjoblist}
				disabaleDropDown={disabaleDropDown}
			/>
		</div>
	);
};

export default CustomerInvoices;
