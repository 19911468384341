/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillQuestionDiamondFill } from 'react-icons/bs';
import allActions from '../../Store/action';
import { useEffect } from 'react';
import { CALL_ACTION_TYPE } from '../../Store/call/Type';
import { BASEURL, twilioPhoneNumberFormatter } from '../../utilites';
import Modal from '../Dashboard/Modal';
import UserAvatar from '../UserAvatar';
import RequestJobDocumentForm from '../forms/RequestJobDocumentForm';
import { FaPhone } from 'react-icons/fa';
import { Device } from '@twilio/voice-sdk';
import AudioCallModal from '../forms/Modal/AudioChatCallModal';
import { IoIosLock } from "react-icons/io";
import VonageAudioCallModal from '../forms/Modal/VonageAudioChatCallModal';
import {
	VonageClient,
  } from '@vonage/client-sdk';
const UserTopBar = ({
	selectedReceiver,
	getMyStatus,
	setMessage,
	handleSubmit,
	setMessageContent,
	editable = true,
	group,
	chatUsers,
	chatDisable,
}) => {
	console.log('@selectedReceiver', selectedReceiver);
	const [lastLead, setLastLead] = useState();
	const [selectedData, setSelectedData] = useState({});
	const [callData, setCallData] = useState({ status: '' });
	const [callMeta, setCallMeta] = useState('');
	const [audioConnection,setAudioConnection] = useState(null)
	const [callerUid,setcallerUid] = useState('')

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [
		{ user },
		{ chatStatus },
		{ videoCallRoom, getZoomMeeting, audioCallToken },
		{ LoginData },
		{saveRecording}
	] = useSelector((state) => [state, state.firebase, state.call, state.user, state.recordings]);
	const showError = (message) => {
		setMessage({
			show: true,
			type: 'Error',
			text: message,
		});
	};
	const toggleModal = () => document.querySelector('.AudioCallModalButton')?.click();
	const toggleModalVonage = () => document.querySelector('.VonageAudioCallModalButton')?.click();
console.log("@@SR", saveRecording)
const client = new VonageClient();
useEffect(()=>{
	client.on("callInvite", (callId, from, channelType) => {
		// Answer / Reject Call
	});
	client.on("callHangup", (callId, callQuality, reason) => {
		toggleModalVonage()
		setCallData({status:''})

	});
	client.on("legStatusUpdate", (callId, legId, status) => {
		if(status==='ANSWERED'){
			setCallData({status:"Connected"})
		}else if(status==='RINGING'){
			setCallData({status:"Ringing..."})
		}	
	});
	
  },[client])


	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!getZoomMeeting.status) {
			// Reset Message and Loading
			resetMessage();

			setMessage({
				show: true,
				type: 'Error',
				text: getZoomMeeting.message,
			});
		}
		if (getZoomMeeting.status) {
			resetMessage();
			// Encode Room ID String
			var encodedString = window.btoa(
				JSON.stringify({
					roomId: videoCallRoom?.data?.meetingID,
					creatorUserserId: LoginData?.id,
					startUrl: getZoomMeeting.data.start_url,
					joinUrl: getZoomMeeting.data.join_url,
				})
			);
			handleSubmit({ type: 'meeting/video', encodedString });
		}
	}, [getZoomMeeting?.status, getZoomMeeting?.message]);

	useEffect(() => {
		// if Idle
		if (videoCallRoom.status === 'idle') resetMessage();

		// if Error While Creating Video Call Room
		if (!videoCallRoom.status) {
			// Reset Message and Loading
			resetMessage();

			setMessage({
				show: true,
				type: 'Error',
				text: videoCallRoom.message,
			});
		}

		// if Success After Creating Video Call
		if (videoCallRoom.status === true) {
			// Get Meeting Status
			if (videoCallRoom?.data?.meetingID) {
				dispatch(allActions.call.getZoomMeeting(videoCallRoom?.data?.meetingID));
			}
		}
	}, [videoCallRoom.status, videoCallRoom.message, videoCallRoom?.data?.meetingID, setMessage]);

	const proceedCall = async (token) => {
		try {
			const device = new Device(token, {
				enableImprovedSignalingErrorPrecision: true,
			});
			setCallMeta(device);
			device.on('registered', function () {
				console.log('Twilio.Device Ready to make and receive calls!');
			});
			device.on('error', function (error) {
				resetMessage();
				showError(error.toString());
			});
			const call = await device.connect({
				params: {
					To: `${twilioPhoneNumberFormatter(selectedReceiver?.phone)}`,
				},
			});
			setAudioConnection(call)
			call.on('error', (callError) => {
				resetMessage();
				toggleModal();
				showError('Something went wrong due to network connectivity!');
			});
			call.on('accept', () => {
				resetMessage();
				toggleModal();
				setCallData({ status: 'Ringing....', user: selectedReceiver });
                if(call?.parameters?.CallSid){
				console.log("heloo", call?.parameters?.CallSid)
				dispatch(allActions.recordings.saveRecording(call?.parameters?.CallSid))
			}
			});
			call.on('disconnect', () => {});
			call.on('cancel', () => {});
		} catch (error) {}
	};
	useEffect(() => {
		// if Error While Creating Video Call Room
		if (!audioCallToken?.status && audioCallToken) {
			// Reset Message and Loading
			resetMessage();
			setMessage({
				show: true,
				type: 'Error',
				text: audioCallToken?.message ? audioCallToken?.message : {},
			});
		}
		if (audioCallToken?.status === true && audioCallToken) {
			console.log('@audioCallToken', audioCallToken);
			proceedCall(audioCallToken?.data?.token);
		}
	}, [audioCallToken?.status, audioCallToken?.message]);

	// Function To Generate Meeting Link
	const CreateMeetingLink = () => {
		// if Customer, do not create meeting link
		if (user.LoginData.isCustomer) return;
		// Just to Pass Validation
		setMessageContent('Meeting Link');

		// Change Loading Status
		changeLoading(true);

		// reset Meeting Link
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));

		// Generate New Room
		const meetingData = {
			duration: CALL_ACTION_TYPE.videoCallTiming.duration,
			email: LoginData?.email,
		};
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.videoCallRoomIdle }));
		dispatch(allActions.call.createRoom(meetingData));
	};

	// Function To Reset Message
	const reset_Message = () => {
		setMessage({ show: false });
	};

	const changeLoading = (status) => {
		dispatch(allActions.global.Loader(status));
	};

	const resetMessage = () => {
		reset_Message();
		changeLoading(false);
	};

	useEffect(() => {
		loadLastLead();
	}, [selectedReceiver]);

	const loadLastLead = () => {
		if (selectedReceiver && selectedReceiver?.role?.toLowerCase() === 'customer') {
			// Get latest lead of selected receiver
			axios
				.get(`${BASEURL}/api/leads/by-customer`, {
					params: {
						id: selectedReceiver?.id,
					},
				})
				.then((res) => setLastLead(res.data.data));
		} else if (lastLead) {
			setLastLead(null);
		}
	};
const vonageCalling= async()=>{
			changeLoading(true);
	axios
		.post(`${BASEURL}/api/vonage/requset-call`)
		.then((response) => {
			client
				.createSession(response.data?.data)
				.then((sessionId) => {
					setCallData({status:'Connecting...'})
					client
						.serverCall({ to: JSON.stringify(Number(twilioPhoneNumberFormatter(selectedReceiver?.phone))),custom_data: JSON.stringify({ user_id: LoginData?.id })})
						.then((callId) => {
							changeLoading(false)
							toggleModalVonage()
							setcallerUid(callId)
						})
						.catch((error) => {
							changeLoading(false);

						});
				})
				.catch((error) => {
					changeLoading(false);

				});
		})
		.catch((error) => {
			changeLoading(false);

		});
}

	const createAudioCallToken = () => {
		console.log("@5555555",process.env.REACT_APP_CALL_SERVICES);
		
		if(process.env.REACT_APP_CALL_SERVICES==='VONAGE'){

			vonageCalling()
		}else{

		
		resetMessage();
		changeLoading(true);
		dispatch(allActions.call.dispatchToStore({ type: CALL_ACTION_TYPE.audioCallTokenIdle }));
		dispatch(allActions.call.createAudioCallToken());
		}
	};

	const CallEnd = async () => {
		try {
			if (callMeta !== '' && callMeta._boundDestroy()) {
				await callMeta?._boundDestroy();
			}
			setCallData({ status: '' });
			setCallMeta('');
			setTimeout(() => {
			}, 2000);
		} catch (error) {
			console.log('ERROR???', error);
		}
	};

	const vonageHangUp = () => {
		if (callerUid) {
			axios.post(`${BASEURL}/api/vonage/hangup-call`,{call_id:callerUid})
			.then((res)=>{
			})
			.catch((err)=>{
			})
		}
		setCallData({status:''})
		//   toggleModalVonage()
	};

	return (
		<div className="clearfix border-bottom border-light">
			<div className="row video-appointment-height">
				<div className="col-6 mb-2">
					{group && (
						<div className="float-start">
							{chatUsers &&
								chatUsers?.map((data) => {
									return (
										<div
											className="d-flex flex-row align-items-center px-2  justify-content-start"
											key={data.id}
										>
											<UserAvatar
												avatar={user.avatar}
												alt={`${user.firstname} ${user.lastname}`}
												className="rounded-circle"
												width="30"
											/>
											<p className="mb-0 px-1">{data?.firstname + ' ' + data?.lastname}</p>-
											<p className="mb-0 px-1">
												{getMyStatus(chatStatus?.data, data?.uuid, 'lastSeen')}
											</p>
										</div>
									);
								})}
						</div>
					)}
					{!group && selectedReceiver && (
						<>
							<div className="float-start">
								<div className="position-relative" style={{ width: 40, height: 40 }}>
									<UserAvatar
										avatar={selectedReceiver.avatar}
										alt={`${selectedReceiver.firstname} ${selectedReceiver.lastname}`}
										className="rounded-circle w-100 h-100 object-fit-cover"
									/>
									<span
										className={`position-absolute rounded-circle border border-light bottom-0 end-0${
											getMyStatus(chatStatus?.data, selectedReceiver?.uuid, 'status') === 'online'
												? ' bg-success'
												: ' bg-white'
										}`}
										style={{
											width: 10,
											height: 10,
										}}
									></span>
								</div>
							</div>
							<div className="d-flex flex-column align-items-start px-2">
								<p className="mb-0">
									{selectedReceiver?.firstname + ' ' + selectedReceiver?.lastname}
								</p>
								<small className="text-capitalize text-responsive w-200 fold-Text-small opacity-75">
									{selectedReceiver?.role} -{' '}
									<small className="text-muted">
										Last Seen:{' '}
										<em>{getMyStatus(chatStatus?.data, selectedReceiver?.uuid, 'lastSeen')}</em>
									</small>
								</small>
							</div>
						</>
					)}
				</div>
				<div className="col-6 my-1">
					<div className="float-end icon-div d-flex">
						{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
								disabled={true}
							>
								Request documents  <IoIosLock style={{ fontSize: '16px' }} />
							</button> : lastLead && lastLead?.jobs?.length > 0 ? (
							<Link
								to="#"
								className={`btn btn-sm btn-outline-primary mx-1 px-2 ${chatDisable && 'disabled'}`}
								data-bs-toggle={chatDisable ? '' : 'modal'}
								data-bs-target={chatDisable ? '' : '#requestDocumentsModal'}
								onClick={() => {
									setSelectedData({
										job_id: lastLead?.jobs[0]?.id,
										pro_id: lastLead?.userpro?.id,
										customer_id: selectedReceiver?.id,
									});
								}}
							>
								Request documents 
							</Link> 
						) : null}
							{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1 mx-2"
								disabled={true}
							>
								Job  <IoIosLock style={{ fontSize: '16px' }} />
							</button> :(lastLead && lastLead?.jobs?.length < lastLead?.services?.length) ? (
							<Link
								to={`/dashboard/jobs/create/${lastLead.uuid}`}
								className={`btn btn-sm btn-outline-primary mx-1 px-2 ${chatDisable && 'disabled'}`}
								onClick={() => {
									if (!chatDisable) {
										dispatch(allActions.dashboard.resetTaxPlanning());
										dispatch(allActions.dashboard.resetTaxPreparation());
									}
								}}
							>
								Create job
							</Link>
						) : null}
						<div className="dropdown">
							{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1 mx-2"
								disabled={true}
							>
								Video Meeting  <IoIosLock style={{ fontSize: '16px' }} />
							</button> :<button
								className={`btn btn-sm btn-outline-primary mx-1 px-2 dropdown-toggle ${
									chatDisable && 'disabled'
								}`}
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								Video Meeting
							</button> }
							<ul className="dropdown-menu">
								<li>
									<button
										className="dropdown-item"
										onClick={() => !chatDisable && navigate('/dashboard/appointments')}
									>
										Schedule Appointment
									</button>
								</li>
								{editable && !user.LoginData.isCustomer && (
									<li>
										<button
											className="dropdown-item"
											onClick={() => {
												!chatDisable && CreateMeetingLink();
											}}
										>
											Instant Meeting
										</button>
									</li>
								)}
							</ul>
						</div>
						{LoginData?.userpro?.status_id === 6 ? <button
								className="btn btn-sm btn-outline-primary px-2 float-md-end float-none d-flex align-tems-center gap-1"
								disabled={true}
							>
								<IoIosLock style={{ fontSize: '16px' }} />
							</button> : !LoginData.isCustomer && !group && (
							<Link
								to="#"
								className="btn btn-sm btn-outline-primary mx-2"
								onClick={() => {
									if (selectedReceiver?.phone) {
										createAudioCallToken();
									} else {
										showError("User done't have added phone number");
										//   phone number missing
									}
								}}
							>
								<FaPhone className="icons" />
							</Link>
						)}
						{editable && (
							<div>
								<Link to={`/faq`} className="btn btn-sm " target="_blank">
									<BsFillQuestionDiamondFill color="#4b57e7" className="icons" />
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
			<Modal
				title="Request Documents"
				bodyClass="px-5 py-4 text-center"
				trigger="requestDocumentsModal"
				showBtn={false}
				btnClass="requestDocumentsModalClose"
				size="lg"
			>
				{lastLead ? (
					<RequestJobDocumentForm
						data={selectedData}
						customer_id={selectedReceiver.id}
						leadData={lastLead?.user}
						reloadData={loadLastLead}
					/>
				) : null}
			</Modal>
			<AudioCallModal
				callData={callData}
				callMeta={callMeta}
				toggleModal={toggleModal}
				selectedReceiver={selectedReceiver}
				CallEnd={CallEnd}
				audioConnection={audioConnection}
			/>
			<VonageAudioCallModal
				selectedReceiver={selectedReceiver}
				hangUp={vonageHangUp}
				callData={callData}

			/>
		</div>
	);
};

export default UserTopBar;
