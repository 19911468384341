/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import axios from 'axios';
import SweetAlert2 from 'react-sweetalert2';
import { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link, useNavigate } from 'react-router-dom';
import { BASEURL, FileTypeIcon } from '../../../utilites';
import { TbFileDescription } from "react-icons/tb";
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import Header from '../../dashboard/components/Header';
import Footer from '../../dashboard/components/Footer';
import Modal from '../../../components/Dashboard/Modal';
import CustomerQuestionnaires from '../../dashboard/leads/components/CustomerQuestionnaires';
import CustomerInvoices from '../../dashboard/leads/components/CustomerInvoices';
import ChooseDocumentForm from '../../../components/forms/ChooseDocumentForm';
import AddDocumentForm from '../../../components/forms/AddDocumentForm';
import AddDisputeForm from '../../../components/forms/AddDisputeForm';
import GetHelpForm from '../../../components/forms/GetHelpForm';
import JobCard from '../components/JobCard';
import Preloader from '../components/Preloader';
import JobPayments from '../components/JobPayments';
import allActions from '../../../Store/action';
import UserAvatar from '../../../components/UserAvatar';
import RenderMultiPagePDF from '../components/RenderMultiPagePDF';
import FileIcon from '../../../assets/img/file.svg'
import OpenJobForm from '../../../components/forms/OpenJobForm';


const OpenFormModal = ({children,}) => {
	return <div className="custom-modal-overlay">
		<div className="custom-modal-cont-create-form" onClick={(e) => e.stopPropagation()}>
			{children}
		</div>
	</div>;
  };

const ProjectDetails = () => {
	const [{ LoginData }] = useSelector((state) => [state.user]);
	const dispatch = useDispatch();
	const { uuid } = useParams();
	const [lead, setLead] = useState(null);
	const [job, setJob] = useState(null);
	const [swalProps, setSwalProps] = useState({});
	const [documentToUpload, setDocumentToUpload] = useState(null);
	const [documentToView, setDocumentToView] = useState();
	const [isFormModalOpen,setIsFormModalOpen] = useState(false)
	const [activeFormData,setActiveFormData] = useState({})
	const [editInvoice, setEditInvoice] = useState(false);


	useEffect(() => {
		setJob(lead?.jobs[0]);
	}, [lead]);


	const totalCostCalulations = (invoices,projectCost) => {
		let cost = 0;
		if(invoices?.length===0){
			cost=projectCost
		}else{	
			cost=projectCost

		invoices?.forEach((element) => {
			if (!element?.name?.includes('subscription')) {
				cost += Number(element?.amount);
			}
		});
	}
		return cost;
	};
	let paidAmount = 0;
	let remainingAmount = 0;
	job?.invoices?.map((data) =>
		data?.payments?.map((i, v) => (paidAmount = paidAmount + parseFloat(i.amount_paid)))
	);
	job?.invoices?.map((data) => (remainingAmount = remainingAmount + parseFloat(data.amount)));
	const loadJobByUUID = useCallback((UUID) => {
		axios
			.get(`${BASEURL}/api/jobs/${UUID}`)
			.then((res) => {
				console.log('job data>>>>', res.data);
				setLead(res.data.data);
			})
			.catch((err) => {
				console.log('error>>>>', err);
			});
	}, []);

	useEffect(() => {
		loadJobByUUID(uuid);
	}, [uuid, loadJobByUUID]);

	const reloadData = () => {
		loadJobByUUID(uuid);
		// Reload documents
		dispatch(allActions.dashboard.getAllExistingDocuments(1));
	};
	// const OpenMyForm = (document, jobId) => {
	// 	if (!jobId || !document) return;
	// 	document.lead_id = jobId;
	// 	dispatch(allActions.dashboard.resetTaxPreparation());
	// 	dispatch(allActions.taxForms.dispatchViewFormDetails({ document, navigate }));
	// };
	const deleteDocument = (uuid) => {
		dispatch(allActions.dashboard.deleteDocument(uuid))
			.then(() => {
				setTimeout(() => reloadData(), 500); // TODO: Fix this
			})
			.catch((error) => console.error(error));
	};

	const handleDeleteDocumentClick = (uuid) => {
		setSwalProps((prev) => ({
			...prev,
			[uuid]: {
				show: true,
				showDenyButton: true,
				title: 'Are you sure?',
				text: 'You will not be able to recover this document!',
				confirmButtonText: 'Yes, delete it!',
				denyButtonText: 'Cancel',
				didClose: () => setSwalProps((prev) => ({ ...prev, [uuid]: { show: false } })),
				onConfirm: () => deleteDocument(uuid),
			},
		}));
	};
	// selections of tab
	const [selectedTab, setSelectedTab] = useState('overview-tab');
	const handleTabClick = (tabId) => {
		setSelectedTab(tabId);
	};

	
	if (lead && job) {
		return (
			<div className="clearfix">
				 {isFormModalOpen && (
                <OpenFormModal setIsFormModalOpen={setIsFormModalOpen}>
                <OpenJobForm  setIsFormModalOpen={setIsFormModalOpen} setActiveFormData={setActiveFormData} activeFormData={activeFormData}/>
                </OpenFormModal>
              )}
				<Header
					name={`${job?.service?.name || job?.others} ${new Date(job.created_at).getFullYear()}`}
					dropdownContent={
						<li>
							<Link
								to="#"
								className="dropdown-item"
								data-bs-toggle="modal"
								data-bs-target="#getHelpModal"
							>
								Get help
							</Link>
						</li>
					}
				/>
				{/* modal for document Viewer */}
				<Modal
					title="View Document"
					bodyClass="px-5 py-4 text-center"
					trigger="viewDocumentModal"
					showBtn={false}
					size="lg"
				>
					{documentToView &&
						(documentToView.location.includes('.pdf') ? (
							<>
								<RenderMultiPagePDF file={documentToView.path} />
							</>
						) : (
							<img
								src={documentToView.path}
								className="w-100 img-fluid rounded"
								alt={documentToView.name}
								style={{ objectFit: 'contain' }}
							/>
						))}
				</Modal>
				<div className="container page">
					<div className="clearfix pt-4 mb-4">
						<div className="d-flex align-items-center">
							<div>
								<UserAvatar
									avatar={lead?.userpro?.user?.avatar}
									alt={`${lead?.userpro?.user?.firstname} ${lead.userpro?.user?.lastname}`}
									className="img-fluid rounded-circle"
									style={{ width: 40, height: 40 }}
								/>
							</div>
							<div className="ms-3 d-flex flex-column justify-content-center">
								<p className="text-black fw-bold m-0">
									{lead?.userpro?.user?.firstname} {lead?.userpro?.user?.lastname}
								</p>
								<p className="text-black m-0 opacity-75" style={{ fontSize: 12 }}>
									{lead?.userpro?.city}
								</p>
							</div>
						</div>
					</div>
					<div className="clearfix">
					<ul className="d-flex list-unstyled p-0 m-0" id="myTab" role="tablist">
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'overview-tab' ? 'active' : ''}`}
									id="overview-tab"
									data-bs-toggle="tab"
									data-bs-target="#overview-tab-pane"
									type="button"
									role="tab"
									aria-controls="overview-tab-pane"
									aria-selected={selectedTab === 'overview-tab'}
									onClick={() => handleTabClick('overview-tab')}
								>
									Overview
								</button>
							</li>
							<li className={`custom-tab-item ${job.status_id === 4 ? 'disabled' : ''}`} role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'documents-tab' ? 'active' : ''}`}
									id="documents-tab"
									data-bs-toggle="tab"
									data-bs-target="#documents-tab-pane"
									type="button"
									role="tab"
									aria-controls="documents-tab-pane"
									aria-selected={selectedTab === 'documents-tab'}
									onClick={() => job.status_id !== 4 && handleTabClick('documents-tab')}
								>
									Documents & Forms{" "}
									<span className="badge bg-primary">
										{job?.form_data?.length ? job?.form_data?.length : job?.documents?.length}
									</span>
								</button>
							</li>
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'invoices-tab' ? 'active' : ''}`}
									id="invoices-tab"
									data-bs-toggle="tab"
									data-bs-target="#invoices-tab-pane"
									type="button"
									role="tab"
									aria-controls="invoices-tab-pane"
									aria-selected={selectedTab === 'invoices-tab'}
									onClick={() => handleTabClick('invoices-tab')}
								>
									Invoices
								</button>
							</li>
							<li className="custom-tab-item" role="presentation">
								<button
									className={`custom-tab-link ${selectedTab === 'questionaires-tab' ? 'active' : ''}`}
									id="questionaires-tab"
									data-bs-toggle="tab"
									data-bs-target="#questionaires-tab-pane"
									type="button"
									role="tab"
									aria-controls="questionaires-tab-pane"
									aria-selected={selectedTab === 'questionaires-tab'}
									onClick={() => handleTabClick('questionaires-tab')}
								>
									Questionnaire
								</button>
							</li>
						</ul>
						<div className="tab-content" id="myTabContent">
							<div
								className="tab-pane fade show active"
								id="overview-tab-pane"
								role="tabpanel"
								aria-labelledby="overview-tab"
								tabIndex="0"
							>
								<div className="row mt-4">
									<div className="col-md-8 ps-md-0">
										<div className="card border border-light rounded-3 p-4">
											<div className="card-header bg-transparent border-0 p-0 mb-3">
												<h3 className="card-title text-primary mb-0 fw-bold fs-3 ff-gothic">
													Project timeline
												</h3>
											</div>

											<div className="card-body">
												<JobCard
													data={job}
													userpro={lead.userpro}
													user={lead.user}
													reload={reloadData}
												/>
											</div>
										</div>
									</div>
									<div className="col-md-4 pe-md-0 mt-4 mt-md-0">
										<div className="card border border-light rounded-3 p-4">
											<div className="card-header bg-transparent border-0 p-0 mb-3">
												<h3 className="card-title text-primary mb-0 fw-bold fs-3 ff-gothic">
													Payments
												</h3>
											</div>

											<div className="card-body px-0">
												<JobPayments
                                                    cost={job.cost}
													// cost={totalCostCalulations(job.invoices,job.cost)}
													paidAmount={paidAmount}
													remaining={job.cost - paidAmount}
													// remaining={totalCostCalulations(job.invoices,job.cost) - paidAmount}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="questionaires-tab-pane"
								role="tabpanel"
								aria-labelledby="questionaires-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Questionnaire</h1>
									<CustomerQuestionnaires data={lead} />
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="documents-tab-pane"
								role="tabpanel"
								aria-labelledby="documents-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
								<h5 className="text-black fw-bold">Tax Forms</h5>
									<div className="clearfix mt-3 px-2">
										{(
											<div className="clearfix">
												{job.form_data && job.form_data.length > 0 ? (
													job.form_data?.map((document) => {
														if (Object.keys(document).includes('formData')) {
															return <div
																className="row align-items-center px-2 py-4 border border-light rounded-3 mb-3"
																key={document.id}
															>
																{/* <div className="col-md-1 d-flex justify-content-center">
																	<div
																		className={`rounded-circle bg-${Object.keys(document?.formData)?.length > 0
																				? 'success'
																				: 'grey-200'
																			} text-white d-flex justify-content-center align-items-center`}
																		style={{
																			width: 20,
																			height: 20,
																		}}
																	>
																		<AiOutlineCheck />
																	</div>
																</div> */}
																<div
																	className="col-md-9 d-flex align-items-center"
																	style={{ gap: 10 }}
																>
																	<img src={FileIcon} alt='file' />
																	<div className=''>
																		<p className='m-0'>{document?.name}</p>
																		<p className='m-0'>{document?.message}</p>
																	</div>
																</div>
																{Object.keys(document?.formData)?.length === 0 ? (
																	<div className="col text-end">
																		<button
																			className={`btn btn-sm btn-primary px-4 py-2 fw-bold ${job?.status_id === 4 && 'disabled'
																				}`}
																			onClick={() => {
																				setIsFormModalOpen(true)
																				setActiveFormData(document?.formData)
																			}}
																		>
																			Open
																		</button>
																	</div>
																) : (
																	<div className="col text-end">
																		<button
																			className="btn btn-sm btn-primary px-4 py-2 fw-bold"
																			onClick={() => {
																				setIsFormModalOpen(true)
																				setActiveFormData({name:document?.name,submitted:document?.submitted,data:document?.formData})
																			}}
																		>
																			Open
																		</button>
																	</div>
																)}
															</div>
														}
													})
												) : (
													<></>
												)}
											</div>
										)}
										<h5 className="text-black fw-bold">Requested Documents</h5>
									<div className="row">
											{job?.documents && job?.documents?.length > 0 ? (
												job?.documents?.map((document) => (
													<div
														className="d-flex  justify-content-between align-items-center py-4 border border-light rounded-3 mb-3 px-4"
														key={document.id}
													>
														<div className="col-md-7 d-flex align-items-center" style={{ gap: 10 }}>
															<TbFileDescription size={28} color="grey" extension={document?.extension} />
															<div>
																<p className='mb-0 mt-0 fw-bold'>{document?.name}</p>
																<p className='mb-0 mt-0'>{document?.jobdocument.description}</p>
																{document?.jobdocument.message && <p className='mb-0 mt-0 text-muted'>{document?.jobdocument.message}</p>}
															</div>
														</div>
														<div className='d-flex flex-row justify-content-end col-md-5'>
														{document.path ? (
															<div className="col text-end">
																<p className="text-muted text-end mb-0">
																	Added{' '}
																	{new Date(document.updated_at).toLocaleDateString('en-US', {
																		year: 'numeric',
																		month: 'short',
																		day: 'numeric',
																	})}
																</p>
															</div>
														) : (
															<button
																className="btn btn-sm border-grey-100 text-dark fw-bold d-flex align-items-center px-2"
																data-bs-toggle="modal"
																data-bs-target="#uploadDocumentModal"
																onClick={() =>
																	setDocumentToUpload({
																		...document,
																		userProUuid: job?.leads?.userpro?.user?.uuid,
																	})
																}
															>
																<AiOutlineCloudUpload size={28} color="grey"  />
																<span>
																	Drag & drop your file here or <span className="text-primary">click here to upload</span>
																</span>
															</button>
														)}
														<div className="col-md-1 text-end text-primary">
												<div className="px-0 btn-group" style={{ width: 30 }}>
													<button
														type="button"
														className={`btn btn-sm text-primary border-0 rounded-circle${!document.path ? ' disabled' : ''
															}`}
														data-bs-toggle="dropdown"
														aria-expanded="false"
														style={{
															width: 30,
															height: 30,
														}}
													>
														<BsThreeDotsVertical />
													</button>
													<ul className="dropdown-menu">
														<li>
															<Link
																to="#"
																className={`dropdown-item${document?.path ? '' : ' disabled'
																	}`}
																data-bs-toggle="modal"
																data-bs-target="#viewDocumentModal"
																onClick={() => setDocumentToView(document)}
															>
																View
															</Link>
															<button
																className="dropdown-item"
																onClick={() => handleDeleteDocumentClick(document.uuid)}
															>
																Delete
															</button>
															<SweetAlert2 {...swalProps[document.uuid]} />
														</li>
													</ul>
												</div>
											</div>
													</div>
													</div>
												))
											) : (
												<></>
											)}

											
										</div>
										{(job?.documents?.length ? job?.documents?.length : job?.form_data?.length) ===
											0 && <p className="text-center mb-0">No documents found</p>}
									</div>
								</div>
							</div>
							<div
								className="tab-pane fade"
								id="invoices-tab-pane"
								role="tabpanel"
								aria-labelledby="invoices-tab"
								tabIndex="0"
							>
								<div className="clearfix border border-light rounded-3 p-4 mt-4">
									<h1 className="text-primary">Invoices</h1>
									<CustomerInvoices activeLead={lead}  activeJob={job} uuid={uuid} 	editInvoice={editInvoice}
										setEditInvoice={setEditInvoice} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					title="Upload Document"
					bodyClass="px-5 py-4 text-center"
					trigger="uploadDocumentModal"
					showBtn={false}
					btnClass="addDocumentModalClose"
					size="lg"
				>
					{documentToUpload ? (
						<Fragment>
							<nav>
								<div className="nav nav-tabs justify-content-center" id="nav-tab" role="tablist">
									<button
										className="nav-link active"
										id="nav-existing-tab"
										data-bs-toggle="tab"
										data-bs-target="#nav-existing"
										type="button"
										role="tab"
										aria-controls="nav-existing"
										aria-selected="true"
									>
										Choose an existing file
									</button>
									<button
										className="nav-link"
										id="nav-fromComputer-tab"
										data-bs-toggle="tab"
										data-bs-target="#nav-fromComputer"
										type="button"
										role="tab"
										aria-controls="nav-fromComputer"
										aria-selected="false"
									>
										Upload from computer
									</button>
								</div>
							</nav>
							<div className="tab-content mt-3" id="nav-tabContent">
								<div
									className="tab-pane show active"
									id="nav-existing"
									role="tabpanel"
									aria-labelledby="nav-existing-tab"
									tabIndex="0"
								>
									<ChooseDocumentForm
										userId={LoginData.id}
										documentId={documentToUpload.id}
										reloadDocuments={reloadData}
										targetUserUuid={documentToUpload?.userProUuid}
									/>
								</div>
								<div
									className="tab-pane"
									id="nav-fromComputer"
									role="tabpanel"
									aria-labelledby="nav-fromComputer-tab"
									tabIndex="0"
								>
									<AddDocumentForm
									    isModal={true}
										userId={LoginData.id}
										multiple={false}
										documentId={documentToUpload.id}
										reloadDocuments={reloadData}
										showDocumentNameField={false}
										targetUserUuid={documentToUpload?.userProUuid}
									/>
								</div>
							</div>
						</Fragment>
					) : null}
				</Modal>
				<Modal
					title="Get help"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="getHelpModal"
					showBtn={false}
					btnClass="getHelpModalClose"
					size="lg"
				>
					<GetHelpForm />
				</Modal>
				<Modal
					title="Dispute details"
					bodyClass="p-4 text-center text-dark text-opacity-50"
					trigger="createDisputeModal"
					showBtn={false}
					btnClass="createDisputeModalClose"
					size="lg"
				>
					<AddDisputeForm jobId={job.id} reload={() => loadJobByUUID(uuid)} />
				</Modal>
				<Footer />
			</div>
		);
	}
	return <Preloader />;
};

export default ProjectDetails;
